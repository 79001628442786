// import { getLatLngAsPaperPoint } from "../shared/diagram-map";
// import { DiagramLayer, getLayer } from "../shared/layer";
import { CircleSelectedObject } from "./CircleSelectedObject";
// import * as paper from "paper";

export class CrashPointObject extends CircleSelectedObject {
    protected applyClassName() {
        this.className = "CrashPointObject";
    }

    // private phantom: paper.Item | undefined = undefined;

    // get locIsDirty() {
    //     return !this.position.equals(getLatLngAsPaperPoint());
    // }

    constructor(entireObject: paper.Item) {
        super(entireObject);
        this.isDiagramObject = false;
        this.entireObject.data.isCrashIcon = true;

        // const existingPhantom = paper.project.getItem({
        //     data: { isPoiPhantom: true }
        // });

        // if (existingPhantom) {
        //     this.phantom = existingPhantom;
        // }
    }

    // onMouseDown(e: paper.MouseEvent): void {
    //     super.onMouseDown(e);
    //     this.togglePhantom("on");
    // }

    // onMouseUp(e: paper.MouseEvent): void {
    //     this.togglePhantom("off");
    //     super.onMouseUp(e);
    // }

    // onAddToGroupSelection() {
    //     this.togglePhantom("on");
    // }

    // onRemoveFromGroupSelection() {
    //     this.togglePhantom("off");
    // }

    // private togglePhantom(state: "on" | "off") {
    //     if (!this.phantom) {
    //         this.phantom = this.core.clone({ insert: false });
    //         this.phantom.data = { isPoiPhantom: true };
    //         this.phantom.opacity = 0.5;
    //     }

    //     if (state === "off") {
    //         this.phantom.remove();
    //     } else {
    //         if (!this.phantom.isInserted()) {
    //             getLayer(DiagramLayer.crashPoint).insertChild(0, this.phantom);
    //         }

    //         this.phantom.position = this.position;
    //     }
    // }

    remove(): void {
        // purposely empty
    }

    get ellipsisOptions(): any[] {
        return [
            {
                text: "Reposition Point of Impact",
                data: "reposition",
                wholeDiagram: false
            },
            {
                text: "Reposition Entire Diagram",
                data: "reposition",
                wholeDiagram: true
            },
            {
                text: "Location Information",
                data: "loc info"
            }
        ];
    }
}
