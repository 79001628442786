import { view } from "paper";
import { to180Angle } from "./helpers";

export enum Cursor {
    Move = "move",
    NWSE_Resize = "nwse-resize",
    NESW_Resize = "nesw-resize",
    NS_Resize = "ns-resize",
    EW_Resize = "ew-resize",
    Grab = "grab",
    Grabbing = "grabbing",
    Spin = 'url("assets/SVG/Misc Icons/refresh.svg") 12 12, crosshair',
    Default = "grab",
    Pointer = "pointer",
    Arrow = "default",
    Crosshair = "crosshair",
    Copy = "copy",
    Hitch = 'url("assets/SVG/Misc Icons/link-50.png") 25 25, cell',
    Split = 'url("assets/SVG/Misc Icons/split-30.png") 15 15, col-resize',
    Wait = "wait"
}

export var cursorIsLocked = false;

export function lockCursor() {
    cursorIsLocked = true;
}

export function unlockCursor() {
    cursorIsLocked = false;
}

export function clearCursor() {
    if (!cursorIsLocked) {
        view.element.style.removeProperty("cursor");
    }
}

export function setCursor(cursor: Cursor | string, force = false) {
    if (!cursorIsLocked || force) {
        view.element.style.cursor = cursor;
    }
}

export function getResizeCursorFor(node: paper.Item, shape: paper.Path) {
    let angle =
        node.position.subtract(shape.position).angle + shape.parent.rotation;

    // limit angle to the -180 to 180 range
    angle = to180Angle(angle);
    const half45 = 45 / 2;
    let cursor = 'ew-resize';

    if (
        (angle >= 90 + half45 && angle <= 180 - half45) ||
        (angle <= -half45 && angle >= -90 + half45)
    ) {
        cursor = 'nesw-resize';
    } else if (
        (angle > 45 + half45 && angle < 135 - half45) ||
        (angle < -45 - half45 && angle > -135 + half45)
    ) {
        cursor = 'ns-resize';
    } else if (
        (angle <= -90 && angle > -180 + half45) ||
        (angle >= 0 + half45 && angle <= 90 - half45)
    ) {
        cursor = 'nwse-resize';
    }

    return cursor;
}
