import { actionManager } from "./Action";
import { enableMapDragging } from "./diagram-map";
import { clearCursor } from "./cursor";
import { unlockLayers } from "./layer";
import { DiagramObject } from "../classes/DiagramObject";
import { GroupSelectionBuilder } from "../classes/GroupSelection";

export var controlKeyPressed = false;

export function setUpKeyHandlers(): void {
    window.addEventListener("blur", () => {
        controlKeyPressed = false;
    });

    window.onkeydown = (e: KeyboardEvent) => {
        if (e.ctrlKey) {
            if (e.key === "z") {
                actionManager.undo();
            } else if (e.key === "y") {
                actionManager.redo();
            } else if (e.key === "a") {
                e.preventDefault();
                DiagramObject.selectAll();
            }

            if (
                e.key === "Control" &&
                !controlKeyPressed
            ) {
                controlKeyPressed = true;
                GroupSelectionBuilder.toggleLasso();
            }
        } else if (
            DiagramObject.selectedObject &&
            (e.key === "Delete" || e.key === "Backspace")
        ) {
            DiagramObject.selectedObject.remove();
            clearCursor();
        } else if (e.key === "Escape") {
            DiagramObject.deselect();
        }
    };

    window.onkeyup = (e: KeyboardEvent) => {
        if (controlKeyPressed && e.key === "Control") {
            controlKeyPressed = false;
            GroupSelectionBuilder.cancelLasso();
            unlockLayers();
            enableMapDragging();
        }

        // if (nodeBeneathCursor && e.key === "Delete") {
        //     deleteNode(nodeBeneathCursor);
        // }
    };
}
