import { MenuItem } from "@progress/kendo-angular-menu";
import { Cursor, lockCursor, setCursor, unlockCursor } from "../shared/cursor";
import { DiagramObject } from "./DiagramObject";
import { NUM_OF_ANCHORS, Participant } from "./Participant";
import { Vehicle } from "./Vehicle";
import { project } from "paper";
import {
    addPostPositionOption,
    addPriorPositionOption,
    arrowColorMenu,
    changeLabelMenu,
    colorMenu,
    defaultOrientationOptions,
    removeOption,
    reverseDirectionOptions
} from "./ellipsis-menu-options";
import { getPositionRelativeToLine } from "../shared/helpers";
import { DiagramService } from "../shared/services/diagram.service";
import {
    DiagramAction,
    DiagramElementAction,
    PropTrackingAction,
    actionManager
} from "../shared/Action";
import {
    finishMajorDraw,
    startMajorDraw
} from "../page-disabler/disable-enable-page";

export class Trailer extends Vehicle {
    protected applyClassName() {
        this.className = "Trailer";
    }

    static readonly HITCHING_RADIUS = 15;
    private readonly MAX_ANGLE = 110;
    private readonly DETACH_THRESHOLD_SCALAR = 1.35;
    private towingParticipantCache?: Participant;

    get ellipsisOptions(): MenuItem[] {
        if (this.isAttached) {
            return [
                changeLabelMenu,
                colorMenu,
                defaultOrientationOptions,
                removeOption
            ];
        }

        const [commonFirst, commonLast] = this.getCommonEllipsisOptions();

        return [changeLabelMenu, ...commonFirst, colorMenu, ...commonLast];
    }

    get isAttached(): Boolean {
        return this.core.data.towingParticipantId != undefined;
    }

    get towingParticipant(): Participant | undefined {
        if (this.towingParticipantCache) return this.towingParticipantCache;
        else if (this.isAttached) {
            const veh = DiagramService.getById(
                this.core.data.towingParticipantId
            ) as Participant;
            this.towingParticipantCache = veh;
            return veh;
        }
        return undefined;
    }

    set towingParticipant(veh: Participant | undefined) {
        if (veh) {
            this.core.data.towingParticipantId = veh.id;
            this.towingParticipantCache = veh;
        } else {
            if (this.towingParticipant)
                this.towingParticipant.trailer = undefined;
            this.towingParticipantCache = undefined;
            this.core.data.towingParticipantId = undefined;
        }
    }

    get hitchingPoint(): paper.Point | undefined {
        return this.towingParticipant?.innerGroup.localToGlobal(
            this.towingParticipant?.core.bounds.bottomCenter
        );
    }

    get hitchTarget(): Participant | undefined {
        if (this.core.data.hitchTarget) {
            return DiagramService.getById(
                this.core.data.hitchTarget
            ) as Participant;
        }

        return undefined;
    }

    set hitchTarget(target: Participant | undefined) {
        this.core.data.hitchTarget = target?.id;
    }

    set outAnchor(pos: number) {
        this.core.data.outAnchor = pos;

        if (this.isAttached) {
            this.towingParticipant!.outAnchor = pos;
        }
    }

    get inAnchor() {
        return super.inAnchor;
    }

    set inAnchor(pos: number) {
        this.core.data.inAnchor = pos;

        if (this.isAttached) {
            this.towingParticipant!.inAnchor = pos;
        }
    }

    get outAnchor() {
        return super.outAnchor;
    }

    /** Makes sure the trailer stays in place on the towing vehicle's hitch */
    reposition(updateArrows = true) {
        if (this.isAttached) {
            this.position = this.hitchingPoint!;
            this.constrainAngle();
            this.repositionLabel();
            if (updateArrows) this.updateArrows();
        }
    }

    /** Makes sure the trailer cannot be rotated on the hitch past the maxTrailerAngle */
    constrainAngle(updateArrows = true) {
        if (!this.towingParticipant) return;

        let angle = this.towingParticipant.rotation - this.rotation;
        angle = Math.abs(angle) > 180 ? angle - 360 * Math.sign(angle) : angle;

        const sign = getPositionRelativeToLine(
            this.towingParticipant.globalPointFor("position"),
            this.towingParticipant.globalPointFor("bounds.bottomCenter"),
            this.globalPointFor("position")
        );

        if (Math.abs(angle) > this.MAX_ANGLE) {
            angle = this.towingParticipant.rotation + this.MAX_ANGLE * sign;
            this.rotation = angle;
        }

        if (updateArrows) this.updateArrows();
    }

    private connectTo(participant: Participant, updateArrows = true) {
        participant.trailer = this;
        this.towingParticipant = participant;
        this.core.opacity = participant.core.opacity;
        // this.isReversing = participant.isReversing;
        this.inAnchor = participant.inAnchor;
        this.outAnchor = participant.outAnchor;
        this.innerGroup.pivot = this.core.bounds.topCenter;
        this.entireObject.pivot = this.globalPointFor("bounds.topCenter");
        this.rotation = participant.rotation;
        this.arrowColor = participant.arrowColor || participant.color;
        this.pivotLocked = true;

        this.reposition(updateArrows);

        if (participant.isReversing) {
            participant.outArrow?.changeOrigin(this);
        }

        if (this.anchorIsInRear(this.inAnchor)) {
            participant.inArrow?.changeTarget(this);
        }

        // hide rotation handle
        this.rotationHandle.visible = false;
        this.requestMenuUpdate();
    }

    protected reverseAnchor(dir: "in" | "out") {
        const anchor = `${dir}Anchor`;
        this[anchor] = this.getOppositeAnchorIndex(this[anchor]);

        if (this.towingParticipant) {
            const arrowProp = `${dir}Arrow`;
            const changeProp = dir === "in" ? "changeTarget" : "changeOrigin";

            const arrow = this[arrowProp] || this.towingParticipant[arrowProp];

            if (arrow) {
                if (this.anchorIsInFront(this[anchor])) {
                    arrow[changeProp](this.towingParticipant);
                } else if (this.anchorIsInRear(this[anchor])) {
                    arrow[changeProp](this);
                }
            }
        }
    }

    // protected reverseAnchor(dir: "in" | "out") {
    //     const anchor = `${dir}Anchor`;
    //     this[anchor] = this.getOppositeAnchorIndex(this[anchor]);

    //     if (this.towingVehicle) {
    //         this.towingVehicle[anchor] = this.getOppositeAnchorIndex(
    //             this.towingVehicle[anchor]
    //         );

    //         const arrowProp = `${dir}Arrow`;
    //         const changeProp = dir === "in" ? "changeTarget" : "changeOrigin";

    //         if (this[arrowProp]) {
    //             this[arrowProp][changeProp](this.towingVehicle);
    //         } else if (this.towingVehicle[arrowProp]) {
    //             this.towingVehicle[arrowProp][changeProp](this);
    //         }
    //     }
    // }

    get nextPosition(): Participant | undefined {
        if (this.isAttached) return undefined;
        return super.nextPosition;
    }

    get prevPosition(): Participant | undefined {
        if (this.isAttached) return undefined;
        return super.prevPosition;
    }

    get connectedParticipant(): Participant | undefined {
        return this.towingParticipant;
    }

    private disconnect() {
        if (!this.towingParticipant) return;

        this.pivotLocked = false;
        this.innerGroup.pivot = this.core.bounds.center;
        this.entireObject.pivot = this.globalPointFor("bounds.center");
        this.core.opacity = 1;

        if (this.towingParticipant.isReversing) {
            this.outArrow?.changeOrigin(this.towingParticipant);
        } else {
            this.inArrow?.changeTarget(this.towingParticipant);
        }

        this.rotationHandle.visible = true;
        this.towingParticipant = undefined;
        this.core.data.ownerId = undefined;
        this.requestMenuUpdate();
    }

    attachTo(vehicle: Participant) {
        startMajorDraw();
        const wasSelected = this.wasSelected;
        DiagramObject.deselect();

        const trailers = [this];
        const allPos = Array.from(vehicle.allPositions);

        for (let i = allPos.indexOf(vehicle) - 1; i >= 0; --i) {
            const t = allPos[i].trailer;

            if (t) {
                if (t.icon == this.icon && t.color == this.color) {
                    continue;
                } else {
                    t.disconnect();
                    t.remove(false);
                }
            }

            const clone = this.clone();
            clone.connectTo(allPos[i], false);
            trailers.push(clone);
        }

        this.connectTo(vehicle, false);

        for (const trailer of trailers) {
            trailer.updateArrows();
        }

        if (wasSelected) DiagramObject.select(this);

        finishMajorDraw();
    }

    changeLabel(text: string): void {
        super.changeLabel(text);

        if (this.isAttached) {
            for (const pos of this.towingParticipant!.allPositions) {
                if (pos.hasTrailer) {
                    pos.trailer!.labelText = text;
                }
            }
        }
    }

    detach() {
        if (!this.towingParticipant) return;

        if (
            DiagramAction.currentAction &&
            DiagramAction.currentAction.name !== "remove"
        ) {
            DiagramAction.currentAction.name = "detach trailer";
        }

        const allPos = this.towingParticipant.allPositions;

        for (
            let i = allPos.indexOf(this.towingParticipant) + 1;
            i < allPos.length;
            ++i
        ) {
            const t = allPos[i].trailer;

            if (t) {
                t.disconnect();
                t.remove(false);
            } else {
                break;
            }
        }

        this.disconnect();
    }

    isBeyondThreshold(point: paper.Point) {
        const trailerHeight = this.core.bounds.height;
        return (
            point.getDistance(this.globalPointFor("bounds.center")) >
            trailerHeight * this.DETACH_THRESHOLD_SCALAR
        );
    }

    /** Returns a vehicle for hitching to if one exists near the supplied point, else undefined */
    static getHitchTarget(p: paper.Point): Vehicle | undefined {
        let candidate: Vehicle | undefined = undefined;

        project.getItem({
            match: (item: paper.Item) => {
                if (item.data.className !== "Vehicle") return false;
                const veh = DiagramService.getById(
                    item.data.referenceId
                ) as Vehicle;
                if (veh.hasTrailer) return false;
                if (
                    veh.innerGroup
                        .localToGlobal(veh.core.bounds.bottomCenter)
                        .isClose(p, this.HITCHING_RADIUS)
                ) {
                    candidate = veh;
                    return true;
                }

                return false;
            }
        });

        return candidate;
    }

    /** @param chain Pass false to skip detach routine. *default: true* */
    remove(chain = true) {
        let removeAction: DiagramElementAction | undefined = undefined;

        if (chain) {
            if (DiagramElementAction.prepareForNewAction()) {
                removeAction = new DiagramElementAction({
                    name: "remove",
                    elementRef: this,
                    isLocked: true
                });
                removeAction.startRecording();
            }
            this.detach();
        } else {
            this.disconnect();
        }

        super.remove();

        if (removeAction) {
            removeAction.stopRecording();
        }
        return undefined;
    }

    onMouseDown(e: paper.MouseEvent): void {
        if (this.isAttached) {
            if (!DiagramAction.isRecording) {
                new PropTrackingAction<paper.Point>({
                    name: "pivot trailer",
                    elementRef: this,
                    propKey: "rotation"
                }).startRecording();
            }
        }

        super.onMouseDown(e);
    }

    onMouseUp(e: paper.MouseEvent) {
        let mutation = DiagramAction.currentAction as PropTrackingAction<any>;

        if (this.hitchTarget) {
            e.stop();
            const hitchTarget = this.hitchTarget;
            this.hitchTarget = undefined;
            this.attachTo(hitchTarget);
            mutation.name = "attach trailer";
        }

        super.onMouseUp(e);
    }

    dragRotate = (e: paper.MouseEvent) => {
        super.dragRotate(e);

        if (this.isAttached) {
            this.constrainAngle(false);
            this.repositionLabel();
        }
    };

    dragMove = (e: paper.MouseEvent) => {
        if (this.isAttached) {
            if (this.isBeyondThreshold(e.point)) {
                this.detach();
            } else {
                const vector = this.hitchingPoint!.subtract(e.point);
                this.rotation = vector.angle + 90;
                this.constrainAngle();
                this.positionEllipsis();
                this.repositionLabel();
            }
        } else {
            super.dragMove(e);

            if (this.allPositions.length === 1) {
                this.hitchTarget = Trailer.getHitchTarget(
                    this.globalPointFor("bounds.topCenter")
                );

                if (this.hitchTarget) {
                    setCursor(Cursor.Hitch, true);
                } else {
                    setCursor(Cursor.Move, true);
                }
            }
        }
    };

    // dragScale = (e: paper.MouseEvent) => {
    //     super.dragScale(e);
    //     this.reposition();
    // };
}
