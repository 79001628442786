import { PopUpMenuComponent } from './../popup-menu/popup-menu.component';
import { environment } from './../../environments/environment';
import { OnInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import Point from '../shared/S4Point';

@Component({
    selector: "top-panel",
    templateUrl: "./top-panel.component.html",
    styleUrls: ["./top-panel.component.scss"]
})
export class TopPanelComponent {
    @Input() hasLoaded = false;
    @Input() controlButtonsVisible: boolean;
    @ViewChild("about") aboutPopup: PopUpMenuComponent;

    get version() {
        return environment.version.version;
    }

    popupPosition = new Point(175, 55);

    openAbout() {
        this.aboutPopup.open(this.popupPosition);
    }
}
