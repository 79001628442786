import { Injectable } from "@angular/core";
import { DiagramData } from "src/app/models/diagram-models";
import { QueryStringService } from "./query-string.service";
import {
    DIAGRAM_STYLES,
    currentOpacity,
    diagramStyle,
    leafletMap,
    selectedLayer
} from "../diagram-map";
import {
    disablePage,
    enablePage,
    promptBox
} from "src/app/page-disabler/disable-enable-page";
import { getCroppingRect } from "src/app/cdt-diagram/cdt-diagram-cropping";
import { UIService } from "./ui.service";
import * as htmlToImage from "html-to-image";
import { CrashFormData } from "src/app/cdt-diagram/cdt-diagram-models";
import * as paper from "paper";

declare function download(
    data: string | Blob,
    strFileName: string,
    strMimeType: string
): void;

export interface SavingParameters {
    mapBounds?: boolean;
    tiles?: boolean;
    opacity?: boolean;
    diagram?: boolean;
    styleCD?: boolean;
}

@Injectable({
    providedIn: "root"
})
export class CRUDService {
    static globalInstance: CRUDService;
    constructor(private ids: QueryStringService, private ui: UIService) {
        CRUDService.globalInstance = this;
    }

    static disableSaving() {
        this.globalInstance.savingIsDisabled = true;
    }

    static enableSaving() {
        this.globalInstance.savingIsDisabled = false;
    }

    private _savedDiagramWasLoaded = false;

    get savedDiagramWasLoaded() {
        return this._savedDiagramWasLoaded;
    }

    async fetchDiagram() {
        const url = `api/diagram/get-saved-diagram/${this.ids.artifactId}/${this.ids.vendorId}`;

        let result: {
            data?: DiagramData;
            ok: boolean;
            msg?: string;
        } = { ok: false };

        try {
            const response = await fetch(url);

            if (!response.ok) {
                result.msg = response.statusText;
                return result;
            } else if (response.status == 204) {
                result.ok = true;
                result.msg = "No save found";
                return result;
            }

            result.data = (await response.json()) as DiagramData;

            if (result.data.json == "Error retrieving diagram from database") {
                result.msg = result.data.json;
                return result;
            }

            result.ok = true;
            this._savedDiagramWasLoaded = true;
        } catch (error: any) {
            result.msg = error.message;
        }

        return result;
    }

    // Saving stuff ================================================================================================================

    private savingIsDisabled = false;
    private ignoreSaveError = false;
    private abortController = new AbortController();
    private abortSignal = this.abortController.signal;
    private isSavingToDB = false;

    enableSaving() {
        this.savingIsDisabled = false;
    }

    disableSaving() {
        this.savingIsDisabled = true;
    }

    async saveDiagram(
        whatToSave: SavingParameters = { diagram: true },
        keepalive = false
    ) {
        if (this.savingIsDisabled) {
            console.log("no saving!");
            return;
        }

        const params = this.getDataToSave(whatToSave);
        const url = `api/diagram/save-crash-diagram/${this.ids.artifactId}/${this.ids.vendorId}`;

        if (this.isSavingToDB) {
            this.abortController.abort(); // abort any previous, unfinished requests (otherwise the database will baulk)
            this.abortController = new AbortController(); // refresh the controller/signal
            this.abortSignal = this.abortController.signal;
        }

        this.isSavingToDB = true;

        try {
            const response = await fetch(url, {
                method: "PUT",
                body: params,
                signal: this.abortSignal,
                keepalive: keepalive
            });
            if (!response.ok) {
                throw new Error("Failed to save diagram");
            }
        } catch (error) {
            if (error instanceof Error) {
                if (error.name != "AbortError" && !this.ignoreSaveError) {
                    const choice = await promptBox(
                        "Error Saving Diagram",
                        "The diagram failed to auto-save. " +
                            "Please resolve any network connectivity issues, " +
                            "else your work may be lost.",
                        ["OK", "Don't show this again!"]
                    );

                    if (choice == "Don't show this again!") {
                        this.ignoreSaveError = true;
                    }

                    console.error(error);
                }
            }
        } finally {
            this.isSavingToDB = false;
        }
    }

    static requestSave() {
        paper.view.element.dispatchEvent(
            new CustomEvent<"save-requested">("save-requested", {
                bubbles: true
            })
        );
    }

    private getDataToSave(params: SavingParameters): FormData {
        const formData = new FormData();

        if (params.mapBounds) {
            const center = leafletMap.getCenter();
            formData.append("panLat", center.lat.toString());
            formData.append("panLng", center.lng.toString());
            formData.append("zoomLvl", leafletMap.getZoom().toString());
        }

        if (params.opacity) {
            formData.append("mapOpacity", currentOpacity.toString());
        }

        if (params.tiles) {
            formData.append("mapTiles", selectedLayer);
        }

        if (params.diagram) {
            formData.append("json", paper.project.exportJSON());
        }

        if (params.styleCD) {
            formData.append(
                "styleCD",
                DIAGRAM_STYLES.indexOf(diagramStyle).toString()
            );
        }

        return formData;
    }

    async saveDiagramImage() {
        const blob = await this.getCroppedDiagramBlob();
        if (!blob) {
            return "canceled";
        }

        const formData = new FormData();
        formData.append("imgData", blob);

        const url = `api/diagram/save-diagram-img/${this.ids.artifactId}/${this.ids.vendorId}`;

        try {
            const response = await fetch(url, {
                method: "PUT",
                body: formData
            });

            if (!response.ok) {
                throw new Error("Failed to save diagram image");
            }

            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async getCroppedDiagramBlob(): Promise<Blob | null> {
        let result: Blob | null = null;
        const element = document.getElementById("map") as HTMLElement;

        this.disableSaving();

        const bounds = await getCroppingRect(this.ui).catch((err) => {
            console.log(err);
        });

        if (bounds) {
            disablePage();

            const blob = await htmlToImage
                .toBlob(element, {
                    filter: (el: HTMLElement) => {
                        if (el.classList && el.classList.contains("dont-save"))
                            return false;
                        return true;
                    }
                })
                .then((blob) => {
                    return blob;
                })
                .catch((error) => {
                    console.error("oops, something went wrong!", error);
                });

            if (blob) {
                const scale = window.devicePixelRatio;

                const bitmap = await createImageBitmap(
                    blob,
                    bounds.left * scale,
                    bounds.top * scale,
                    bounds.width * scale,
                    bounds.height * scale,
                    { resizeQuality: "high" }
                ).catch((err) => {
                    console.log(err);
                });

                if (bitmap) {
                    const tempCanvas = document.createElement(
                        "canvas"
                    ) as HTMLCanvasElement;
                    tempCanvas.width = bounds.width * scale;
                    tempCanvas.height = bounds.height * scale;
                    const ctx = tempCanvas.getContext("2d");
                    ctx?.drawImage(bitmap, 0, 0);
                    let quality = 1;

                    do {
                        result = await new Promise((resolve) => {
                            tempCanvas.toBlob(
                                (resultBlob) => {
                                    resolve(resultBlob);
                                },
                                "image/jpeg",
                                quality
                            );
                        });
                        quality -= 0.01;
                    } while (result && result.size > 437012);

                    console.log("Quality =", (quality += 0.01));
                }
            }

            enablePage();
        }

        const legends = paper.project.getItems({ data: { thing: "legend" } });

        for (const legend of legends) {
            legend.remove();
        }

        this.enableSaving();
        this.saveDiagram({ diagram: true, mapBounds: true });

        return result;
    }

    async downloadDiagramImageJpeg() {
        const blob = await this.getCroppedDiagramBlob();

        if (blob) {
            download(blob, "crash_diagram.jpeg", "image/jpeg");
        }
    }

    async getCrashFormData(
        artId?: number,
        vendorId?: string
    ): Promise<CrashFormData | undefined> {
        if (!artId) artId = this.ids.artifactId;
        if (!vendorId) vendorId = this.ids.vendorId;

        const url = `api/diagram/get-crash-data/${artId}/${vendorId}`;

        return fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        "Problem getting crash form data from database"
                    );
                }

                return response.json();
            })
            .then((crashData: CrashFormData) => {
                return crashData;
            })
            .catch((error) => {
                console.error("Error retrieving crash report data:", error);
                return undefined;
            });
    }

    async getHistoricCrashFormData(
        hsmvNbr: number
    ): Promise<CrashFormData | undefined> {
        const url = `api/diagram/get-historic-data/${hsmvNbr}`;

        return fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        "Problem getting crash form data from database"
                    );
                }

                return response.json();
            })
            .then((crashData: CrashFormData) => {
                return crashData;
            })
            .catch((error) => {
                console.error("Error retrieving crash report data:", error);
                return undefined;
            });
    }

    // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
}
