import { Annotation } from './Annotation';
import * as paper from 'paper';
import { DiagramLayer } from 'src/app/shared/layer';
import { LineTool, LineToolBuilder } from './LineTool';

export class PolyLineToolBuilder extends LineToolBuilder {
    protected initialLayer: DiagramLayer =
        DiagramLayer.foregroundAnnotations;

    public constructor(btn: HTMLButtonElement) {
        super(btn, 'straight');
    }

    protected startShape(point: paper.Point): void {
        const line = new paper.Path.Line({
            from: point,
            to: point,
            name: 'shape',
            strokeColor: Annotation.defaultStrokeColor
                ? Annotation.defaultStrokeColor
                : new paper.Color('black'),
            strokeWidth: Annotation.defaultStrokeWidth,
            dashArray: Annotation.defaultDashArray,
            strokeCap: 'round',
            data: {
                thing: 'annotation line',
                category: 'annotation',
                subtype: 'straight',
            },
        });
        line.data.lastClick = Date.now();
        this.unfinishedAnno = this.initAnno(line);
    }

    protected continueShape(point: paper.Point): void {
        if (!this.unfinishedAnno) return;

        const line = this.shape;
        line.lastSegment.point = point;
        const now = Date.now();

        if (now - line.data.lastClick <= 200) {
            paper.view.off(this.activeHandlers);
            line.lastSegment.remove();

            if (line.segments.length < 2) {
                this.toggle();
                return;
            }

            this.setUpArrows();
            this.completeShape();
        } else {
            line.add(new paper.Segment(point));
            line.data.lastClick = now;
        }
    }

    protected toolMove = (e: paper.MouseEvent) => {
        if (!this.unfinishedAnno) return;

        this.shape.lastSegment.point = e.point;
    };

    build(): Annotation {
        const polyLineTool = new LineTool(this.unfinishedAnno!);
        return polyLineTool;
    }
}
