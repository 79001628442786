import { RectangleNode, RectangleToolBuilder } from "./RectangleTool";
import * as paper from "paper";
import { MenuItem } from "@progress/kendo-angular-menu";
import { DiagramService } from "src/app/shared/services/diagram.service";
import { Annotation } from "./Annotation";
import {
    borderColorOptions,
    fillColorOptions,
    fontColorOptions,
    fontSizeOptions,
    lineStyleOptions,
    lineWeightOptions,
    removeOption
} from "../ellipsis-menu-options";
import { DiagramLayer, ProjectLayer } from "src/app/shared/layer";
import { GroupSelection } from "../GroupSelection";
import {
    DiagramElementAction,
    PropTrackingAction
} from "src/app/shared/Action";
import { GeometricShape } from "./GeometricShape";
import { getLatLngAsPaperPoint } from "src/app/shared/diagram-map";

export class TextboxToolBuilder extends RectangleToolBuilder {
    protected projectLayer = ProjectLayer.textboxes;
    protected initialLayer: DiagramLayer = DiagramLayer.foregroundAnnotations;
    private tempBorder = false;

    protected setDefaultStyles(shape: paper.Path) {
        super.setDefaultStyles(shape);
        shape.fillColor = Annotation.defaultTextboxFillColor;
    }

    protected preventInvisibleShape(shape: paper.Path) {
        if (super.preventInvisibleShape(shape)) {
            this.tempBorder = true;
            return true;
        }

        return false;
    }

    protected createResizeNodes(): paper.Path.Circle[] {
        return this.makeNodesAlongShape(TextboxNode);
    }

    build(): TextboxTool {
        if (this.tempBorder) this.shape.strokeColor = null;
        const textBoxTool = new TextboxTool(this.unfinishedAnno!);
        textBoxTool.repositionRotationNode();
        return textBoxTool;
    }
}

export class TextboxTool extends GeometricShape {
    protected applyClassName() {
        this.className = "TextboxTool";
    }

    static textboxDiv?: HTMLDivElement;
    private _textArea?: HTMLTextAreaElement;
    static defaultBold = false;
    private static topZIndex = 0;

    static lockTextboxes() {
        if (this.textboxDiv) {
            this.textboxDiv.style.pointerEvents = "none";
        }
    }

    static unlockTextboxes() {
        if (this.textboxDiv) {
            this.textboxDiv.style.removeProperty("pointer-events");
        }
    }

    constructor(entireObject: paper.Item) {
        super(entireObject);

        if (!TextboxTool.textboxDiv) {
            TextboxTool.textboxDiv = document.querySelector(
                "#textboxes"
            ) as HTMLDivElement;
        }

        this.createOrRecreateTextArea();
    }

    get rotation() {
        return super.rotation;
    }

    set rotation(angle: number) {
        super.rotation = angle;
        if (this.textArea) {
            this.textArea.style.transform = `rotate(${angle}deg)`;
        }
    }

    get ellipsisOptions(): MenuItem[] {
        return [
            fontSizeOptions,
            fontColorOptions,
            {
                text: "Bold",
                data: "bold"
            },
            lineWeightOptions,
            lineStyleOptions,
            borderColorOptions,
            fillColorOptions,
            removeOption
        ];
    }

    setUpEventHandlers(): void {
        super.setUpEventHandlers();
    }

    private get textArea(): HTMLTextAreaElement {
        return (
            this._textArea ||
            (TextboxTool.textboxDiv?.querySelector(
                `#${this.id}`
            ) as HTMLTextAreaElement)
        );
    }

    private set textArea(textArea: HTMLTextAreaElement) {
        this._textArea = textArea;
    }

    private get content(): string {
        if (!this.shape.data.content) return "";
        return this.shape.data.content;
    }

    private set content(content: string) {
        if (!content) content = "";
        this.shape.data.content = content;
    }

    get fontSize() {
        return this.shape.data.fontSize ?? Annotation.defaultFontSize;
    }

    set fontSize(size: number) {
        if (this.textArea) this.textArea.style.fontSize = `${size}px`;
        this.shape.data.fontSize = Annotation.defaultFontSize = size;
    }

    get fontColor() {
        return this.shape.data.fontColor ?? Annotation.defaultFontColor;
    }

    set fontColor(color: paper.Color | null) {
        if (this.textArea) {
            this.shape.data.fontColor = Annotation.defaultFontColor = color;
            this.textArea.style.color = color?.toCSS(false) ?? "none";
        }
    }

    get fillColor() {
        return this.shape.fillColor;
    }

    set fillColor(color: paper.Color | null) {
        this.textArea?.style.setProperty(
            "background",
            color?.toCSS(false) ?? "transparent"
        );
    }

    onMouseDown(e: paper.MouseEvent): void {
        if (this.isSelected) {
            super.onMouseDown(e);
            const curAct = PropTrackingAction.currentAction;

            if (
                (!PropTrackingAction.isRecording ||
                    curAct?.name === "edit content") &&
                !this.movingIsDisabled
            ) {
                if (curAct instanceof PropTrackingAction) {
                    if (curAct.propHasChanged) {
                        this.content = this.textArea.value;
                        curAct.stopRecording();
                    } else curAct.cancel();
                }
                new PropTrackingAction<paper.Point>({
                    name: "translate",
                    elementRef: this,
                    propKey: "position",
                    propComparator: (a, b) => a.equals(b)
                }).startRecording();
            }
        }
    }

    private createOrRecreateTextArea() {
        if (!this._textArea) {
            this.textArea = document.createElement(
                "textarea"
            ) as HTMLTextAreaElement;
            this.textArea.id = this.id;
            this.textArea.style.pointerEvents = "none";
            this.textArea.style.zIndex = `${TextboxTool.topZIndex}`;
            this.textArea.classList.add("textbox");

            this.textArea.addEventListener("mouseover", (ev) => {
                ev.preventDefault();
            });
            this.textArea.addEventListener("change", () => {
                const contentChange =
                    PropTrackingAction.currentAction as PropTrackingAction<string>;
                if (contentChange?.name === "edit content") {
                    if (contentChange.propHasChanged) {
                        this.content = this.textArea.value;
                        if (this.isInvisible && !this.content) {
                            contentChange.cancel();
                            return; // passes on to blur event
                        }
                        contentChange.stopRecording();

                    } else {
                        contentChange.cancel();
                    }
                }
            });
            this.textArea.addEventListener("blur", () => {
                if (
                    this.isInvisible &&
                    !this.content &&
                    !PropTrackingAction.isRecording
                ) {
                    this.remove();
                }
            });
            this.textArea.addEventListener("keydown", (ev) => {
                if (this.isSelected) {
                    if (ev.ctrlKey) {
                        if (
                            (ev.key === "z" || ev.key === "y") &&
                            !PropTrackingAction.currentAction?.name.includes(
                                "edit content"
                            )
                        ) {
                            ev.preventDefault();
                            return;
                        }
                    } else if (ev.key === "Delete" && !this.textArea?.value) {
                        this.remove();
                    } else if (
                        !PropTrackingAction.isRecording ||
                        PropTrackingAction.currentAction?.name === "translate"
                    ) {
                        if (
                            PropTrackingAction.currentAction?.name ===
                            "translate"
                        ) {
                            PropTrackingAction.currentAction.name =
                                "translate and edit content";
                        } else {
                            new PropTrackingAction<string>({
                                name: "edit content",
                                elementRef: this,
                                propKey: "textArea.value"
                            }).startRecording();
                        }
                    }

                    ev.stopImmediatePropagation();
                } else {
                    ev.preventDefault();
                    ev.stopPropagation();
                }
            });
            this.textArea.addEventListener("mousedown", (ev) => {
                const e = new MouseEvent(ev.type, {
                    clientX: ev.clientX,
                    clientY: ev.clientY
                });
                paper.view.element.dispatchEvent(e);
            });
            this.textArea.addEventListener("drag", (ev) => {
                const e = new MouseEvent(ev.type, {
                    clientX: ev.clientX,
                    clientY: ev.clientY
                });
                paper.view.element.dispatchEvent(e);
            });
            this.textArea.addEventListener("mouseup", (ev) => {
                const e = new MouseEvent(ev.type, {
                    clientX: ev.clientX,
                    clientY: ev.clientY
                });
                paper.view.element.dispatchEvent(e);
                ev.preventDefault();
            });

            const textboxDiv = document.getElementById(
                "textboxes"
            ) as HTMLDivElement;
            textboxDiv.appendChild(this.textArea);
        }

        if (!this.textArea) return;

        this.updateTextAreaWidthAndPosition();
        this.rotation = this.rotation;
        this.fontSize = this.fontSize;
        this.fontColor = this.fontColor;
        this.fillColor = this.fillColor;

        if (TextboxTool.defaultBold) {
            this.textArea.style.fontWeight = "bold";
        } else {
            this.textArea.style.removeProperty("font-weight");
        }

        this.textArea.value = this.content;
    }

    updateTextAreaWidthAndPosition(groupSelection?: GroupSelection) {
        if (!this.textArea) return;

        const rect = this.getTextAreaRect();
        this.textArea.style.width = `${rect.width}px`;
        this.textArea.style.height = `${rect.height}px`;
        this.textArea.style.left = `${rect.x}px`;
        this.textArea.style.top = `${rect.y}px`;

        if (groupSelection) {
            this.textArea.style.transform = `rotate(${
                this.rotation + groupSelection.rotation
            }deg)`;
        }
    }

    private getTextAreaRect() {
        const crashPointPos = getLatLngAsPaperPoint();
        const rect = new paper.Rectangle(this.shape.internalBounds).expand(-10);
        const center = this.shape.localToGlobal(this.shape.position);

        rect.center = center.subtract(crashPointPos);

        return rect;
    }

    changeFontSize(size: number) {
        if (this.fontSize != size) {
            const changeFontSizeAction = new DiagramElementAction({
                name: "change font size",
                elementRef: this
            });
            changeFontSizeAction.startRecording();
            this.fontSize = size;
            changeFontSizeAction.stopRecording();
        }
    }

    changeFontColor(color: paper.Color | null) {
        const changeFontColorAction = new DiagramElementAction({
            name: "change font color",
            elementRef: this
        });
        changeFontColorAction.startRecording();
        this.fontColor = color;
        changeFontColorAction.stopRecording();
    }

    changeFillColor(color: paper.Color | null) {
        if (!color && !this.shape.strokeColor && !this.content) {
            this.remove();
            return;
        } // remove if invisible

        const changeFillColorAction = new DiagramElementAction({
            name: "change fill color",
            elementRef: this
        });
        changeFillColorAction.startRecording();

        this.fillColor = color;
        if (!color) color = Annotation.nearlyInvisible;
        this.shape.fillColor = Annotation.defaultTextboxFillColor = color;

        changeFillColorAction.stopRecording();

    }

    changeStrokeColor(color: paper.Color | null) {
        if (
            !color &&
            (!this.shape.fillColor ||
                this.shape.fillColor.equals(Annotation.nearlyInvisible)) &&
            !this.content
        ) {
            this.remove();
            return;
        } // remove if invisible

        const changeStrokeColorAction = new DiagramElementAction({
            name: "change stroke color",
            elementRef: this
        });
        changeStrokeColorAction.startRecording();
        this.shape.strokeColor = Annotation.defaultStrokeColor = color;
        changeStrokeColorAction.stopRecording();

    }

    toggleBold() {
        if (this.textArea) {
            TextboxTool.defaultBold = this.shape.data.isBold =
                !this.shape.data.isBold;

            if (this.shape.data.isBold) {
                this.textArea.style.fontWeight = "bold";
            } else {
                this.textArea.style.removeProperty("font-weight");
            }
        }
    }

    select() {
        super.select();
        if (this.textArea) {
            this.textArea.style.pointerEvents = "inherit";
            this.textArea.style.zIndex = `${++TextboxTool.topZIndex}`;
            this.textArea.focus();
        }
    }

    remove(): void {
        PropTrackingAction.currentAction?.name === "edit content" &&
            PropTrackingAction.currentAction?.cancel();
        this.removeTextArea();
        super.remove();
    }

    removeTextArea() {
        this.textArea?.remove();
    }

    dragMove(e: paper.MouseEvent) {
        super.dragMove(e);
        this.updateTextAreaWidthAndPosition();
    }
}

export class TextboxNode extends RectangleNode {
    protected applyClassName() {
        this.className = "TextboxNode";
    }

    get parentElement(): TextboxTool {
        return super.parentElement as TextboxTool;
    }

    onMouseDrag(e: paper.MouseEvent) {
        super.onMouseDrag(e);
        this.parentElement.updateTextAreaWidthAndPosition();
    }
}
