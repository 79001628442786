import * as paper from "paper";
import {
    clearCursor,
    Cursor,
    lockCursor,
    setCursor,
    unlockCursor
} from "../shared/cursor";
import { UIService } from "../shared/services/ui.service";
import {
    disablePage,
    enablePage,
    promptBox
} from "../page-disabler/disable-enable-page";
// import { loadSVGFromFile } from "./cdt-diagram";
import {
    disableMapDragging,
    enableMapDragging,
    getDiagramZoomScale,
    goHome,
    leafletMap,
    paperPointToLatLng
} from "../shared/diagram-map";
import { DiagramObject } from "../classes/DiagramObject";
import {
    createMapTetheredLayer,
    DiagramLayer,
    getLayer,
    lockLayers,
    unlockLayers
} from "../shared/layer";
import {
    getDiagramBounds,
    getDiagramObjectsBounds,
    getInverseScaling
} from "../shared/helpers";
import { DiagramService } from "../shared/services/diagram.service";
import { CRUDService } from "../shared/services/crud.service";

export const defaultCropPadding = 250;
export const resizeNodeRadius = 8;

// const buttonSize = 50;

const BOUNDS_PADDING = 50;
let minCropBounds: paper.Rectangle;
let diagramBounds: paper.Rectangle;
let cropArea: paper.Path;
let grayWall: paper.Path;
let grayOut: paper.PathItem;
let resizeNodes: paper.Group;
// let acceptButton: paper.Group, cancelButton: paper.Group;
// let acceptIcon: paper.Item, cancelIcon: paper.Item;
let northArrow: paper.Group;
let notDrawnToScaleLegend: paper.Group;
let s4Watermark: paper.Item;
let ui: UIService;
// let sidePanelToggle: HTMLButtonElement;
let resolver: Function;
let rejector: Function;

// const buttonEvents = {
//     mouseenter: buttonEnter,
//     mouseleave: buttonLeave,
//     click: buttonClick
// };

function loadMiscIcon(name: string, insert = false) {
    const iconSet = document.querySelector(`#misc-ui`);
    const icon = iconSet?.querySelector(
        `[data-icon-id="${name}"]`
    ) as SVGElement;
    return paper.project.importSVG(icon, { insert: insert });
}

export async function getCroppingRect(
    uiService: UIService
): Promise<paper.Rectangle> {
    uiService.isCropping = true;
    CRUDService.disableSaving();

    // disable anything distracting
    disablePage();
    DiagramObject.deselect();
    lockLayers();

    ui = uiService;
    ui.isDataGridCollapsed = true;
    ui.sidePanelOpen = false;
    ui.gridDisabled = true;
    ui.controlButtonsVisible = false;

    // sidePanelToggle = document.querySelector(
    //     "#side-panel-toggle"
    // ) as HTMLButtonElement;
    // sidePanelToggle.disabled = true;

    // center the user on the diagram objects
    goHome(uiService.padding, getDiagramBounds(defaultCropPadding));
    await new Promise((res) => {
        setTimeout(() => {
            res(null);
        }, 500);
    });

    // create the cropping UI
    const northArrowShape = loadMiscIcon("north arrow");
    northArrowShape.bounds = new paper.Rectangle([0, 0], [25, 33]);
    const bgColor = new paper.Color(255, 255, 255, 0.65);
    let bg = new paper.Path.Rectangle({
        // radius: northArrowShape.bounds.width * 0.1,
        size: northArrowShape.bounds.expand(8),
        fillColor: bgColor,
        radius: new paper.Size(5, 5)
    });
    bg.position = northArrowShape.position;
    // bg.position.y += 30;
    northArrow = new paper.Group([bg, northArrowShape]);
    northArrow.applyMatrix = false;
    northArrow.pivot = bg.bounds.topRight;
    northArrow.remove();
    // northArrow.bounds = new paper.Rectangle([0, 0], [25, 25])

    // "Not drawn to scale"
    const notDrawnToScaleText = new paper.PointText({
        content:
            "Disclaimer: background imagery does not depict time of crash. Diagram not to scale.",
        fontWeight: "bold"
    });
    bg = new paper.Path.Rectangle({
        size: notDrawnToScaleText.bounds.size.add(5),
        fillColor: bgColor,
        radius: new paper.Size(5, 5)
    });
    bg.position = notDrawnToScaleText.position;
    notDrawnToScaleLegend = new paper.Group([bg, notDrawnToScaleText]);
    // notDrawnToScaleLegend.fitBounds(new paper.Rectangle(0, 0, width, 85));

    notDrawnToScaleLegend.remove();

    // S4 Watermark
    s4Watermark = loadMiscIcon("s4icon");

    s4Watermark.opacity = 0.25;

    northArrow.data.thing = "legend";
    notDrawnToScaleLegend.data.thing = "legend";
    s4Watermark.data.thing = "legend";

    createMapTetheredLayer(DiagramLayer.cropping);
    diagramBounds = getDiagramObjectsBounds().expand(BOUNDS_PADDING);
    minCropBounds = getMinBounds();
    cropArea = new paper.Path.Rectangle(
        minCropBounds.expand(defaultCropPadding)
    );

    // Button icons ======================================
    // acceptIcon = await loadSVGFromFile(
    //     "assets/SVG/Misc Icons/check-solid.svg",
    //     false
    // );
    // cancelIcon = await loadSVGFromFile(
    //     "assets/SVG/Misc Icons/xmark-solid.svg",
    //     false
    // );
    // ===================================================

    setUpCroppingUI();

    // add necessary event listeners
    window.addEventListener("resize", onWindowResize);
    leafletMap.on("move", mapMovedUpdate);
    leafletMap.on("zoomend", fixUISize);

    // now we're ready to allow the user to choose the cropping area
    window.addEventListener("keydown", cancelOnEscapeKey);
    enablePage();

    return new Promise<paper.Rectangle>((resolve, reject) => {
        resolver = resolve;
        rejector = reject;
    });
}

function cancelOnEscapeKey(e: KeyboardEvent) {
    if (e.key == "Escape") {
        exitCropping(false);
    }
}

// function buttonEnter(e: paper.MouseEvent) {
//     setCursor(Cursor.Pointer);
//     e.currentTarget.children[0].strokeColor = new paper.Color("#fefefe");

//     const accepted = e.currentTarget.name == "accept button";
//     if (accepted) {
//         e.currentTarget.children[0].fillColor = new paper.Color("#00bf00");
//     }
//     else {
//         e.currentTarget.children[0].fillColor = new paper.Color("#fb1010");
//     }
// }

// function buttonLeave(e: paper.MouseEvent) {
//     e.currentTarget.children[0].strokeColor = null;
//     e.currentTarget.children[0].fillColor = new paper.Color("#fefefe");

//     paper.project.view.element.style.removeProperty("cursor");
// }

// function buttonClick(e: paper.MouseEvent) {
//     const accepted = e.currentTarget.name == "accept button";
//     exitCropping(accepted);
// }

export async function exitCropping(accepted: boolean) {
    window.clearTimeout(windowResizeTimeout);
    window.clearTimeout(windowResizeTimeout2);
    resizingWindow = false;

    if (accepted) {
        if (!paper.view.bounds.contains(cropArea.bounds)) {
            // Try centering
            await new Promise<void>((res) => {
                leafletMap.panTo(
                    paperPointToLatLng(
                        paper.view.projectToView(cropArea.bounds.center)
                    ),
                    { animate: false }
                );

                setTimeout(() => {
                    res();
                }, 500);
            });

            if (!paper.view.bounds.contains(cropArea.bounds)) {
                await helpUserFitBounds();

                return;
            }
        }

        // save the extent
        saveBounds();
    }

    disablePage();
    window.removeEventListener("keydown", cancelOnEscapeKey);
    removeCroppingUIAndListeners();

    // the legends must be added to a captured layered, and then removed in the image processing function
    getLayer(DiagramLayer.groupSelection).addChildren([
        northArrow,
        notDrawnToScaleLegend,
        s4Watermark
    ]);

    setTimeout(() => {
        getLayer(DiagramLayer.main).activate();
        if (accepted) {
            resolver(
                new paper.Rectangle(
                    paper.view.projectToView(cropArea.bounds.topLeft),
                    paper.view.projectToView(cropArea.bounds.bottomRight)
                )
            );
        } else {
            rejector("canceled");
        }

        enablePage();
        ui.isCropping = false;
    }, 50);
}

function saveBounds() {
    const crashPoint = DiagramService.crashPointObject.position;
    getLayer(DiagramLayer.main).data.savedCropArea = {
        x: crashPoint.x - cropArea.bounds.left,
        y: crashPoint.y - cropArea.bounds.top,
        width: cropArea.bounds.width,
        height: cropArea.bounds.height,
        zoom: leafletMap.getZoom()
    };
}

async function helpUserFitBounds() {
    // let offerFullscreen = false;

    // if (
    //     document.fullscreenEnabled &&
    //     new paper.Rectangle(
    //         [0, 0],
    //         [screen.availWidth, screen.availHeight]
    //     ).contains(cropArea.bounds)
    // ) {
    //     const diagram = document.getElementById("map");
    //     await diagram?.requestFullscreen({ navigationUI: "hide" });
    //     await exitCropping(true);
    //     document.exitFullscreen();
    // }

    await promptBox(
        "Diagram Bounds Outside Window",
        `The diagram's bounds must be fully contained in the window.
        Please maximize the window, then click "Continue." S4 Diagram will attempt to fit the bounds.
        Afterwards, make any adjustments and click "Done" again to finish.`,
        ["Continue"]
    );

    goHome(ui.padding, getDiagramBounds());
}

function removeCroppingUIAndListeners() {
    paper.project.activeLayer.remove();
    window.removeEventListener("resize", onWindowResize);
    leafletMap.off("move", mapMovedUpdate);
    leafletMap.off("zoomend", fixUISize);
    ui.gridDisabled = false;
    if (ui.helpBar.isShown()) {
        ui.helpBar.close();
        setTimeout(() => {
            ui.controlButtonsVisible = true;
        }, 500);
    } else {
        ui.controlButtonsVisible = true;
    }
    // sidePanelToggle.disabled = false;
    unlockLayers();
}

function addResizeNode(rect: paper.Path, pos: string): paper.Path {
    const zoomScale = getDiagramZoomScale();
    const rectBounds = rect.bounds;
    const node = new paper.Path.Circle({
        center: rectBounds[pos],
        radius: resizeNodeRadius / zoomScale,
        fillColor: "#fefefe",
        strokeWidth: 1 / zoomScale,
        name: pos,
        data: { thing: "resize node", position: pos },
        applyMatrix: false
    });

    const diagonalOffset = 2 / zoomScale;
    const offset = diagonalOffset / 2;

    switch (pos) {
        case "topLeft":
            node.position.x += diagonalOffset;
            node.position.y += diagonalOffset;
            node.data.offset = new paper.Point(diagonalOffset, diagonalOffset);
            node.data.controls = ["top", "left"];
            node.data.cursor = Cursor.NWSE_Resize;
            break;
        case "topRight":
            node.position.x -= diagonalOffset;
            node.position.y += diagonalOffset;
            node.data.offset = new paper.Point(-diagonalOffset, diagonalOffset);
            node.data.controls = ["top", "right"];
            node.data.cursor = Cursor.NESW_Resize;
            break;
        case "bottomLeft":
            node.position.x += diagonalOffset;
            node.position.y -= diagonalOffset;
            node.data.offset = new paper.Point(diagonalOffset, -diagonalOffset);
            node.data.controls = ["bottom", "left"];
            node.data.cursor = Cursor.NESW_Resize;
            break;
        case "bottomRight":
            node.position.x -= diagonalOffset;
            node.position.y -= diagonalOffset;
            node.data.offset = new paper.Point(
                -diagonalOffset,
                -diagonalOffset
            );
            node.data.controls = ["bottom", "right"];
            node.data.cursor = Cursor.NWSE_Resize;
            break;
        case "leftCenter":
            node.position.x += offset;
            node.data.offset = new paper.Point(offset, 0);
            node.data.controls = ["left"];
            node.data.cursor = Cursor.EW_Resize;
            break;
        case "topCenter":
            node.position.y += offset;
            node.data.offset = new paper.Point(0, offset);
            node.data.controls = ["top"];
            node.data.cursor = Cursor.NS_Resize;
            break;
        case "rightCenter":
            node.position.x -= offset;
            node.data.offset = new paper.Point(-offset, 0);
            node.data.controls = ["right"];
            node.data.cursor = Cursor.EW_Resize;
            break;
        case "bottomCenter":
            node.position.y -= offset;
            node.data.offset = new paper.Point(0, -offset);
            node.data.controls = ["bottom"];
            node.data.cursor = Cursor.NS_Resize;
            break;
    }
    node.onMouseEnter = () => {
        setCursor(node.data.cursor);
        node.scale(1.5);
        node.strokeColor = new paper.Color("#fefefe");
        node.fillColor = new paper.Color("#3d8aff");
    };
    node.onMouseLeave = () => {
        clearCursor();
        node.scaling = new paper.Point(1, 1);
        node.strokeColor = null;
        node.fillColor = new paper.Color("#fefefe");
    };
    node.onMouseDrag = resizeNodeDrag;
    node.onMouseDown = () => {
        lockCursor();
        diagramBounds = getDiagramObjectsBounds().expand(BOUNDS_PADDING);
        leafletMap.dragging.disable();
    };
    node.onMouseUp = (e: paper.MouseEvent) => {
        unlockCursor();
        if (!node.contains(e.point)) clearCursor();
        leafletMap.dragging.enable();
    };

    return node;
}

function getMinBounds() {
    return diagramBounds
        .expand(BOUNDS_PADDING)
        .unite(notDrawnToScaleLegend.bounds.expand(5));
}

function resizeNodeDrag(e: paper.MouseEvent) {
    minCropBounds = getMinBounds();
    const oldRect = cropArea.bounds;

    switch (e.currentTarget.data.position) {
        case "topLeft":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    Math.min(e.point.x, minCropBounds.left),
                    Math.min(e.point.y, minCropBounds.top)
                ),
                oldRect.bottomRight
            );

            break;
        case "topRight":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    Math.max(e.point.x, minCropBounds.right),
                    Math.min(e.point.y, minCropBounds.top)
                ),
                oldRect.bottomLeft
            );

            break;
        case "bottomLeft":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    Math.min(e.point.x, minCropBounds.left),
                    Math.max(e.point.y, diagramBounds.bottom)
                ),
                oldRect.topRight
            );

            break;
        case "bottomRight":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    Math.max(e.point.x, minCropBounds.right),
                    Math.max(e.point.y, diagramBounds.bottom)
                ),
                oldRect.topLeft
            );

            break;
        case "leftCenter":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    Math.min(e.point.x, minCropBounds.left),
                    oldRect.top
                ),
                oldRect.bottomRight
            );

            break;
        case "topCenter":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    oldRect.left,
                    Math.min(e.point.y, minCropBounds.top)
                ),
                oldRect.bottomRight
            );

            break;
        case "rightCenter":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    Math.max(e.point.x, minCropBounds.right),
                    oldRect.top
                ),
                oldRect.bottomLeft
            );

            break;
        case "bottomCenter":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    oldRect.left,
                    Math.max(e.point.y, diagramBounds.bottom)
                ),
                oldRect.topRight
            );

            break;
    }

    for (const node of resizeNodes.children) {
        let w = cropArea.bounds[node.data.position].add(node.data.offset);
        node.position = w;
    }

    // acceptButton.bounds.topLeft = cropArea.bounds.topRight.add(
    //     new paper.Point(10, 5)
    // );

    // cancelButton.bounds.topCenter = acceptButton.bounds.bottomCenter.add(
    //     new paper.Point(0, 5)
    // );

    positionLegends();
    resizeGrayZone();
}

function resizeGrayZone() {
    grayOut.remove();
    grayOut = grayWall.subtract(cropArea);
    grayOut.fillColor = new paper.Color(0, 0, 0, 0.75);
}

async function addResizeNodeGroup() {
    resizeNodes = new paper.Group([
        addResizeNode(cropArea, "topLeft"),
        addResizeNode(cropArea, "topRight"),
        addResizeNode(cropArea, "bottomLeft"),
        addResizeNode(cropArea, "bottomRight"),
        addResizeNode(cropArea, "leftCenter"),
        addResizeNode(cropArea, "topCenter"),
        addResizeNode(cropArea, "rightCenter"),
        addResizeNode(cropArea, "bottomCenter")
    ]);
}

async function setUpCroppingUI() {
    minCropBounds = getDiagramObjectsBounds();
    const savedCropArea = getLayer(DiagramLayer.main).data.savedCropArea;
    let boundsToUse = minCropBounds.expand(defaultCropPadding);

    if (savedCropArea) {
        const zoomLvl = leafletMap.getZoom();
        const zoomScale = leafletMap.getZoomScale(zoomLvl, savedCropArea.zoom);

        const crashPoint = DiagramService.crashPointObject.core.position;

        let savedCropAreaRect = new paper.Rectangle(
            crashPoint.x - savedCropArea.x * zoomScale,
            crashPoint.y - savedCropArea.y * zoomScale,
            savedCropArea.width * zoomScale,
            savedCropArea.height * zoomScale
        );

        // If the last used bounds still make sense, reuse it wherever it doesn't extend beyond the boundaries of the view
        if (savedCropAreaRect.contains(minCropBounds)) {
            boundsToUse = savedCropAreaRect.intersect(
                paper.view.bounds.expand(-10)
            );

            // unless this is inside diagramBounds
            if (!boundsToUse.contains(minCropBounds)) {
                boundsToUse = minCropBounds.expand(5);
            }
        }
    }

    cropArea = new paper.Path.Rectangle(boundsToUse);
    cropArea.strokeWidth = 3;
    cropArea.strokeColor = new paper.Color("white");
    cropArea.data.thing = "crop area";
    // cropArea.onMouseEnter = () => {
    //     setCursor(Cursor.Move);
    // };
    // cropArea.onMouseLeave = () => {
    //     clearCursor();
    // };
    // cropArea.onMouseDown = (e: paper.MouseEvent) => {
    //     disableMapDragging();
    //     cropArea.pivot = e.point;
    // };
    // cropArea.onMouseUp = () => {
    //     enableMapDragging();
    //     cropArea.pivot = cropArea.bounds.center;
    // };
    // cropArea.onMouseDrag = (e: paper.MouseEvent) => {
    //     const minBounds = getMinBounds();
    //     const newBounds = cropArea.bounds.clone();
    //     newBounds.center = newBounds.center.add(cropArea.pivot.subtract(e.point));
    //     if (newBounds.contains(minBounds)) {
    //         cropArea.position = e.point;
    //         resizeGrayZone();
    //         fixUISize();
    //     }
    // };
    grayWall = new paper.Path.Rectangle(paper.view.bounds);
    grayOut = grayWall.subtract(cropArea);
    grayOut.fillColor = new paper.Color(0, 0, 0, 0.75);

    fixUISize();
    paper.project.activeLayer.addChild(northArrow);
    paper.project.activeLayer.addChild(notDrawnToScaleLegend);
    paper.project.activeLayer.addChild(s4Watermark);
    // addButtons();

    ui.helpBar.show(
        "Select the desired diagram bounds by resizing the cropping rectangle and click Done to finish.",
        5000
    );
}

let windowResizeTimeout: number;
let windowResizeTimeout2: number;
let resizingWindow = false;

function onWindowResize() {
    resizingWindow = true;
    saveBounds();
    paper.project.activeLayer.removeChildren();
    window.clearTimeout(windowResizeTimeout);
    window.clearTimeout(windowResizeTimeout2);
    windowResizeTimeout = window.setTimeout(() => {
        ui.isDataGridCollapsed = true;
        goHome(ui.padding, getDiagramBounds(defaultCropPadding));

        windowResizeTimeout2 = window.setTimeout(() => {
            setUpCroppingUI();
            resizingWindow = false;
            fixUISize();
        }, 1000);
    }, 250);
}

function mapMovedUpdate() {
    if (!grayWall.bounds.contains(paper.view.bounds)) {
        grayWall.bounds = grayWall.bounds.unite(paper.view.bounds);
        resizeGrayZone();
    }
}

function fixUISize() {
    if (resizingWindow) {
        return;
    }

    cropArea.strokeWidth = 3 / getDiagramZoomScale();
    sizeAndPositionLegends();

    if (!cropArea.bounds.contains(notDrawnToScaleLegend.bounds)) {
        cropArea.bounds = cropArea.bounds.unite(notDrawnToScaleLegend.bounds);
        resizeGrayZone();
        positionLegends();
    }

    if (resizeNodes) resizeNodes.remove();
    addResizeNodeGroup();

    // acceptButton.remove();
    // cancelButton.remove();
    // addButtons();
}

function sizeAndPositionLegends() {
    const scale = getDiagramZoomScale();
    northArrow.scaling = getInverseScaling(scale);
    notDrawnToScaleLegend.fitBounds(
        new paper.Rectangle(0, 0, 525, 85).scale(1 / scale)
    );
    s4Watermark.fitBounds(new paper.Rectangle(0, 0, 40, 40).scale(1 / scale));
    positionLegends(scale);
}

function positionLegends(scale?: number) {
    if (!scale) scale = getDiagramZoomScale();
    northArrow.position = cropArea.bounds.topRight.add(
        new paper.Point(-5 / scale, 5 / scale)
    );
    notDrawnToScaleLegend.bounds.bottomCenter =
        cropArea.bounds.bottomCenter.add(new paper.Point(0, -5));
    s4Watermark.bounds.topLeft = cropArea.bounds.topLeft.add(
        new paper.Point(4 / scale, 4 / scale)
    );
}

// function addButtons() {
//     const inverseZoomScale = 1 / getDiagramZoomScale();
//     const acceptButtonBackground = new paper.Path.Rectangle(
//         new paper.Rectangle(0, 0, 25, 25),
//         new paper.Size(5, 5)
//     );
//     acceptIcon.fitBounds(acceptButtonBackground.bounds.expand(-10));
//     acceptButtonBackground.fillColor = new paper.Color("#fefefe");
//     acceptButtonBackground.strokeWidth = 2;
//     acceptButtonBackground.position = acceptIcon.position;
//     acceptButton = new paper.Group([acceptButtonBackground, acceptIcon]);
//     acceptButton.scale(inverseZoomScale);
//     acceptButton.bounds.topLeft = cropArea.bounds.topRight.add(
//         new paper.Point(10 * inverseZoomScale, 5 * inverseZoomScale)
//     );
//     acceptButton.name = "accept button";

//     const cancelButtonBackground = new paper.Path.Rectangle(
//         new paper.Rectangle(0, 0, 25, 25),
//         new paper.Size(5, 5)
//     );
//     cancelIcon.fitBounds(cancelButtonBackground.bounds.expand(-5));
//     cancelButtonBackground.fillColor = new paper.Color("#fefefe");
//     cancelButtonBackground.strokeWidth = 2;
//     cancelButtonBackground.position = cancelIcon.position;
//     cancelButton = new paper.Group([cancelButtonBackground, cancelIcon]);
//     cancelButton.scale(inverseZoomScale);
//     cancelButton.bounds.topCenter = acceptButton.bounds.bottomCenter.add(
//         new paper.Point(0, 5 * inverseZoomScale)
//     );
//     cancelButton.name = "cancel button";

//     acceptButton.on(buttonEvents);
//     cancelButton.on(buttonEvents);
// }
