import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { DiagramService } from "../shared/services/diagram.service";
import { QueryStringService } from "../shared/services/query-string.service";
import { CrashReportService } from "../shared/services/crash-report.service";

@Component({
    selector: "demo-page",
    templateUrl: "./demo-page.component.html",
    styleUrls: ["./demo-page.component.scss"]
})
export class DemoPageComponent implements OnInit {
    diagramImage: SafeUrl;

    constructor(
        private sanitizer: DomSanitizer,
        private diagram: DiagramService,
        private ids: QueryStringService,
        private rptService: CrashReportService
    ) {
        this.diagramImage = "";
        this.diagram.isReady.value = false;
    }

    async ngOnInit() {
        await this.rptService.getDiagramImage(this.artId);
        this.diagramImage = this.rptService.demoImage
            ? this.sanitizer.bypassSecurityTrustUrl(this.rptService.demoImage)
            : "";
    }

    get artId() {
        return this.ids.artifactId || 1;
    }

    get vendorId() {
        return this.ids.vendorId || "123";
    }
}
