import { MenuItem } from "@progress/kendo-angular-menu";
import { ungroup } from "../shared/helpers";
import { Arrow } from "./Arrow";
import { CircleSelectedObject } from "./CircleSelectedObject";
import { DiagramObject } from "./DiagramObject";
import { Participant } from "./Participant";
import * as paper from "paper";
import { DiagramElement } from "./DiagramElement";
import { DiagramService } from "../shared/services/diagram.service";
import { DiagramAction } from "../shared/Action";
import { NonMotorist } from "./NonMotorist";
import { TextboxTool } from "./AnnotationTools/TextboxTool";
import { LineTool } from "./AnnotationTools/LineTool";
import {
    DiagramLayer,
    getLayer,
    lockLayers,
    unlockLayers
} from "../shared/layer";
import { controlKeyPressed } from "../shared/global-event-states";
import { CircleSelectedObjectBuilder } from "./ObjectBuilders";
import { clearCursor, setCursor, Cursor } from "../shared/cursor";
import { enableMapDragging, disableMapDragging } from "../shared/diagram-map";
import { Trailer } from "./Trailer";

export class GroupSelection extends CircleSelectedObject {
    protected applyClassName() {
        this.className = "GroupSelection";
    }

    private contained_cache: DiagramElement[] | undefined;
    private containedParticipants_cache: Participant[] | undefined;
    private skipUpdateArrows_cache: Arrow[] | undefined;

    get contained(): DiagramElement[] {
        if (this.contained_cache) {
            return this.contained_cache;
        }

        return (this.contained_cache = (
            this.entireObject.data.contained as Array<string>
        ).map((v) => {
            return DiagramService.getById(v);
        }));
    }

    set contained(elements: DiagramElement[]) {
        this.contained_cache = elements;
        this.entireObject.data.contained = elements.map((v) => v.id);
    }

    get containedParticipants(): Participant[] {
        if (this.containedParticipants_cache) {
            return this.containedParticipants_cache;
        }

        return (this.containedParticipants_cache = this.contained.filter(
            (v) => v instanceof Participant
        ) as Participant[]);
    }

    get skipUpdateArrows(): Arrow[] {
        if (this.skipUpdateArrows_cache) {
            return this.skipUpdateArrows_cache;
        }

        return (this.skipUpdateArrows_cache = (
            this.entireObject.data.skipUpdateArrows as Array<string>
        ).map((v) => DiagramService.getById(v)) as Arrow[]);
    }

    set skipUpdateArrows(arrows: Arrow[]) {
        this.skipUpdateArrows_cache = arrows;
        this.entireObject.data.skipUpdateArrows = arrows.map((v) => v.id);
    }

    get ellipsisOptions(): MenuItem[] {
        return [
            {
                text: "Remove All",
                data: "delete"
            }
        ];
    }

    constructor(entireObject: paper.Item);
    constructor(
        entireObject: paper.Group,
        elements: DiagramElement[],
        skipUpdateArrows: Arrow[]
    );
    constructor(
        entireObject: paper.Group,
        elements?: DiagramElement[],
        skipUpdateArrows?: Arrow[]
    ) {
        super(entireObject);
        if (skipUpdateArrows && elements) {
            this.skipUpdateArrows = skipUpdateArrows;
            this.contained = elements;
        } else {
            this.fixTextboxes();
        }

        /* Lassoing works by grabbing contained diagram objects.
            Since we don't want to reselect a group selection, set this property to false */
        this.entireObject.data.isDiagramObject = false;

        // groupSelectionObject.pivot = getLatLngAsPaperPoint();

        // Unlike regular selections, group selection circles are slightly shaded
        this.selectionCircle.fillColor = new paper.Color(
            "rgba(254, 254, 254, 0.25)"
        );

        DiagramObject.select(this);

        for (const obj of this.contained) {
            obj.onAddToGroupSelection();
        }

        if (controlKeyPressed) lockLayers();
    }

    onMouseDown(e: paper.MouseEvent): void {
        super.onMouseDown(e);
        e.stopPropagation();
    }

    onMouseUp(e: paper.MouseEvent) {
        this.fixChildArrows();
        this.fixTextboxes();
        super.onMouseUp(e);
    }

    // needed to prevent infinite loop as deselect calls remove which calls deselect
    private isDeselecting = false;

    deselect(): void {
        if (this.isDeselecting) return;

        this.isDeselecting = true;
        let ungroupAction: DiagramAction | undefined = undefined;

        if (DiagramAction.prepareForNewAction()) {
            ungroupAction = new DiagramAction({
                name: "ungroup selection",
                isLocked: true
            });
            ungroupAction.startRecording();
        }

        for (const obj of this.contained) {
            obj.onRemoveFromGroupSelection();
        }

        this.ungroup();
        super.remove();
        unlockLayers();

        // We don't actually want to record this action
        // but it makes it so 'remove' doesn't record an action
        ungroupAction?.cancel();
        this.isDeselecting = false;
    }

    select() {
        super.select();
        lockLayers(DiagramLayer.groupSelection);
    }

    private ungroup() {
        this.innerGroup.applyMatrix = true;
        this.innerGroup.matrix.apply();
        ungroup(this.core as paper.Group);
    }

    remove(): void {
        if (DiagramAction.prepareForNewAction()) {
            const removeGroup = new DiagramAction({
                name: "delete group",
                isLocked: true
            });
            removeGroup.startRecording();
            this.ungroup();
            for (const obj of this.contained) {
                if (obj instanceof Arrow) continue;
                obj.remove();
            }
            super.remove();
            unlockLayers();
            removeGroup.stopRecording();
        }
    }

    setUpRotationHandleEvents(): void {
        super.setUpRotationHandleEvents();
        const superRotationHandleMouseUp = this.rotationHandle.onMouseUp!;
        this.rotationHandle.onMouseUp = (e: paper.MouseEvent) => {
            this.fixChildArrows();
            superRotationHandleMouseUp(e);
        };
    }

    dragRotate = (e: paper.MouseEvent) => {
        super.dragRotate(e);
        this.fixChildLabels(this.rotation);
        this.fixChildArrows();
        this.fixTextboxes();
    };

    dragMove(e: paper.MouseEvent): void {
        super.dragMove(e);
        this.fixChildArrows();
        this.fixTextboxes();
    }

    fixChildLabels(newAngle: number) {
        for (const p of this.containedParticipants) {
            p.label.rotation = -newAngle;

            if (p instanceof NonMotorist) {
                p.repositionLabel(this);
            }
        }

        for (const c of this.contained) {
            if (c instanceof LineTool && c.subtype == "measure") {
                c.measurementLabel.rotation = -newAngle;
                c.updateMeasurementLabel();
            }
        }
    }

    fixChildArrows() {
        Arrow.trackUpdates(this.skipUpdateArrows);
        for (const participant of this.containedParticipants) {
            participant.updateArrows();
        }
        Arrow.untrackUpdates();
    }

    fixTextboxes() {
        for (const c of this.contained) {
            if (c instanceof TextboxTool) {
                c.updateTextAreaWidthAndPosition(this);
            }
        }
    }
}

export class GroupSelectionBuilder extends CircleSelectedObjectBuilder {
    constructor() {
        super(true, false, true, false);
    }

    build(items: paper.Item[] | DiagramElement[]): GroupSelection {
        if (items.length < 2)
            throw new Error(
                "Tried to create group selection without multiple objects"
            );

        // Clear any currently visible selection UI so it isn't calculated into the radius of the new group selection
        DiagramObject.deselect();

        let objects: DiagramElement[];

        if (items[0] instanceof paper.Item) {
            objects = (items as paper.Item[]).map((item) =>
                DiagramService.getById(item.data.referenceId)
            );
        } else {
            objects = items as DiagramElement[];
        }

        // Add any trailers that weren't already selected that are connected to objects in objects.
        // Also, add any towers of trailers that weren't already selected that are connected to trailers in objects
        // Also, add arrows when both the start and end objects are in the selection
        const objectSet = new Set(objects); // using Set for increased performance
        const arrows = new Set<Arrow>();
        const skipArrows = new Set<Arrow>();

        for (const obj of objects) {
            if (obj instanceof Participant) {
                if (obj.connectedParticipant) {
                    objectSet.add(obj.connectedParticipant);
                }

                for (const arrow of obj.arrows) {
                    if (arrows.has(arrow)) {
                        objectSet.add(arrow);
                        skipArrows.add(arrow);
                    } else {
                        arrows.add(arrow);
                    }
                }
            }
        }

        getLayer(DiagramLayer.groupSelection).activate();
        getLayer(DiagramLayer.groupSelection).locked = false;

        const group = new paper.Group(
            [...objectSet].map((v) => v.entireObject)
        );
        group.name = "object core";
        const entireObject = this.commonBuildSteps(group);

        return new GroupSelection(
            entireObject,
            [...objectSet],
            [...skipArrows]
        );
    }

    // static functions ==================================================================

    static lasso: paper.Path.Rectangle | undefined;
    static lassoIsToggled = false;

    static toggleLassoIfActive() {
        if (this.lassoIsToggled) {
            this.toggleLasso();
        }
    }

    private static startLasso(e: paper.MouseEvent) {
        this.lasso = new paper.Path.Rectangle(e.point, new paper.Size(1, 1));
        this.lasso.strokeWidth = 2;
        this.lasso.strokeColor = new paper.Color("#fb1010"); //85, 224, 215);
        this.lasso.dashArray = [3, 7];
        this.lasso.data.startPoint = e.point;
        this.lasso.pivot = e.point;

        paper.view.on("mousedrag", this.dragLasso);
    }

    private static dragLasso = (e: paper.MouseEvent) => {
        if (this.lasso) {
            const rect = new paper.Rectangle(
                this.lasso.data.startPoint,
                e.point
            );

            // must prevent width or height of bounds being set to 0 or the matrix gets messed up
            if (rect.width == 0) {
                rect.width = 1;
            }
            if (rect.height == 0) {
                rect.height = 1;
            }

            this.lasso.bounds = rect;
        }
    };

    private static async endLasso(e: paper.MouseEvent) {
        if (this.lasso) {
            this.lasso.bounds = new paper.Rectangle(
                this.lasso.data.startPoint,
                e.point
            );

            // All objects fully surrounded:
            let lassoed = paper.project.getItems({
                inside: this.lasso.bounds,
                data: { isDiagramObject: true }
            });

            if (!lassoed.length) {
                // No object was fully surrounded.
                // Perhaps this is a control-click-to-add or an attempt to lasso a single object...
                lassoed = paper.project.getItems({
                    overlapping: this.lasso.bounds,
                    data: { isDiagramObject: true }
                });

                if (!lassoed.length || lassoed.length > 1) {
                    // User control-clicked on nothing or partially surrounded multiple objects
                    // DiagramObject.deselect();
                    this.cancelLasso();
                    return;
                }
            }

            const selection = DiagramObject.selectedObject;

            if (selection) {
                if (selection instanceof GroupSelection) {
                    // Add the difference between the new lasso and the current selection
                    const difference = lassoed.filter(
                        (x) => !selection.core.children.includes(x)
                    );

                    if (difference.length) {
                        lassoed = selection.core.children.concat(difference);
                    } else {
                        // But if only already selected items were lassoed, remove them from the group
                        // Also removed connected participants from lassoed, so that they are re-added by the build routine
                        const connectedParticipantsToBeRemoved =
                            new Set<paper.Item>();

                        lassoed = selection.core.children.filter((x) => {
                            if (!lassoed.includes(x)) {
                                return true;
                            }

                            const gettingRemoved = DiagramService.getById(
                                x.data.referenceId
                            ) as DiagramObject;

                            if (
                                gettingRemoved instanceof Participant &&
                                gettingRemoved.connectedParticipant
                            ) {
                                connectedParticipantsToBeRemoved.add(
                                    gettingRemoved.connectedParticipant
                                        .entireObject
                                );
                            }

                            return false;
                        });

                        lassoed = lassoed.filter(
                            (x) => !connectedParticipantsToBeRemoved.has(x)
                        );
                    }

                    // remove any arrows
                    lassoed = lassoed.filter(
                        (object: paper.Item) => object.data.className != "Arrow"
                    );
                } else if (
                    // If a single object was previously selected, add it to the group (unless it itself is the only member of the group)
                    !(
                        lassoed.length == 1 &&
                        lassoed[0] == selection.entireObject
                    )
                ) {
                    lassoed.push(selection.entireObject);
                }
            }

            // console.log(lassoed.map((v) => v.data.className));
            DiagramObject.deselect();

            if (lassoed.length > 1) {
                new this().build(lassoed);
            } else if (lassoed.length == 1) {
                // Regular selection of the 1 lassoed object
                DiagramObject.select(
                    DiagramService.getByItem(lassoed[0]) as DiagramObject
                );
            }
        }

        this.cancelLasso();
    }

    static cancelLasso() {
        if (this.lasso) {
            this.lasso.remove();
            this.lasso = undefined;
        }

        this.lassoIsToggled = true;
        this.toggleLasso();

        paper.view.off("mousedrag", this.dragLasso);
    }

    static selectAll() {
        const everything = paper.project.getItems({
            data: { isDiagramObject: true }
        });

        if (everything.length) {
            new GroupSelectionBuilder().build(everything);
        }
    }

    static toggleLasso() {
        const lassoButton = document.querySelector(
            "#Select"
        ) as HTMLButtonElement;

        if (this.lassoIsToggled) {
            if (!controlKeyPressed) {
                lassoButton.classList.remove("btn-toggled");
                clearCursor();
                paper.view.off(this.groupSelectionHandlers);
                enableMapDragging();
                if (
                    DiagramObject.selectedObject instanceof GroupSelection ==
                    false
                ) {
                    unlockLayers();
                }
                getLayer(DiagramLayer.main).activate();
                window.removeEventListener("keydown", this.turnOffLassoWithEsc);
                window.removeEventListener(
                    "mousedown",
                    this.turnOffLassoOutsideDiagram
                );
                window.removeEventListener("blur", this.cancelLassoOnBlur);
            } else {
                lockLayers(); // user is lassoing some new stuff, so don't let current selection move
            }
        } else {
            lassoButton.classList.add("btn-toggled");
            // DiagramObject.deselect();
            setCursor(Cursor.Crosshair);
            disableMapDragging();
            lockLayers();
            getLayer(DiagramLayer.groupSelection).activate();
            paper.view.on(this.groupSelectionHandlers);
            window.addEventListener("keydown", this.turnOffLassoWithEsc);
            window.addEventListener(
                "mousedown",
                this.turnOffLassoOutsideDiagram
            );
            window.addEventListener("blur", this.cancelLassoOnBlur);
        }

        this.lassoIsToggled = !this.lassoIsToggled;
    }

    private static cancelLassoOnBlur = () => {
        this.cancelLasso();
    };

    private static turnOffLassoWithEsc = (e: KeyboardEvent) => {
        if (e.key == "Escape") {
            this.cancelLasso();
        }
    };

    private static turnOffLassoOutsideDiagram = (e: MouseEvent) => {
        if (!(e.target instanceof HTMLElement)) {
            this.cancelLasso();
            return;
        }

        if (e.target.tagName != "CANVAS") {
            this.cancelLasso();
        }
    };

    private static groupSelectionHandlers = {
        mousedown: this.startLasso.bind(this),
        mouseup: this.endLasso.bind(this)
    };
}
