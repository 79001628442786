import { DiagramService } from "./../shared/services/diagram.service";
import { IconId } from "./../shared/icon";
import { PopUpMenuComponent } from "./../popup-menu/popup-menu.component";
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Input,
    ViewChild
} from "@angular/core";
import * as paper from "paper";
import {
    ContextMenuComponent,
    ContextMenuSelectEvent,
    MenuItem
} from "@progress/kendo-angular-menu";
import { CrashReportWindowService, UIService } from "../shared/services";
import { animalsIcons } from "../shared/icon";
import Point from "../shared/S4Point";
import { CRUDService } from "../shared/services/crud.service";
import { DiagramObject } from "../classes/DiagramObject";
import { Participant } from "../classes/Participant";
import { Trailer } from "../classes/Trailer";
import { Vehicle } from "../classes/Vehicle";
import { NonMotorist } from "../classes/NonMotorist";
import {
    ParticipantBuilder,
    SignAndObjectBuilder
} from "../classes/ObjectBuilders";
import { Annotation } from "../classes/AnnotationTools/Annotation";
// import { repositionCrashPoint } from "../geoloc-point-menu/crash-point-update";
import { GeometricShape } from "../classes/AnnotationTools/GeometricShape";
import { CircleSelectedObject } from "../classes/CircleSelectedObject";
import { ParkingStallTool } from "../classes/AnnotationTools/ParkingStallTool";
import { LineTool } from "../classes/AnnotationTools/LineTool";
import { TextboxTool } from "../classes/AnnotationTools/TextboxTool";
import { SelectionService } from "../shared/services/selection.service";
import {
    DiagramAction,
    DiagramElementAction,
    actionManager
} from "../shared/Action";
import { GeolocPointMenuComponent } from "../geoloc-point-menu/geoloc-point-menu.component";

interface ParticipantAttributes {
    type: new (entireObject: paper.Item) => Participant;
    subtype?: string;
    orientation?: string;
}

@Component({
    selector: "cdt-diagram",
    templateUrl: "./cdt-diagram.component.html",
    styleUrls: ["./cdt-diagram.component.scss"]
})
export class CdtDiagramComponent implements AfterViewInit {
    @ViewChild("ellipsisMenu")
    public ellipsisMenu: ContextMenuComponent;
    @ViewChild("animalSwap")
    public animalSwap: PopUpMenuComponent;
    @ViewChild("locInfo")
    public locInfo: PopUpMenuComponent;
    @ViewChild("diagram")
    private diagramCanvasRef: ElementRef<HTMLCanvasElement>;
    @ViewChild("map")
    private mapDivRef: ElementRef<HTMLDivElement>;
    @ViewChild("textboxes")
    private textboxesDivRef: ElementRef<HTMLDivElement>;
    @ViewChild("canHitch")
    private canHitchIconRef: ElementRef<HTMLImageElement>;
    @ViewChild("geolocMenu")
    private geolocMenu: GeolocPointMenuComponent;

    @Input() isEnlarged: boolean;
    private hitchTarget: Vehicle | undefined = undefined;
    private lastSelected: DiagramObject | undefined;
    private ellipsisItems: MenuItem[];
    private menuItemsHaveChanged = false;

    get diagramCanvas() {
        return this.diagramCanvasRef.nativeElement;
    }

    get mapDiv() {
        return this.mapDivRef.nativeElement;
    }

    get canHitchIcon() {
        return this.canHitchIconRef.nativeElement;
    }

    get textboxesDiv() {
        return this.textboxesDivRef.nativeElement;
    }

    get items() {
        if (
            this.menuItemsHaveChanged ||
            this.lastSelected != DiagramObject.selectedObject
        ) {
            this.lastSelected = DiagramObject.selectedObject;
            if (this.lastSelected) {
                this.ellipsisItems = this.lastSelected.ellipsisOptions;
                this.menuItemsHaveChanged = false;
            }
        }

        return this.ellipsisItems;
    }

    get selection() {
        return DiagramObject.selectedObject as Participant | undefined;
    }

    get menuInputValue() {
        const selected = DiagramObject.selectedObject;

        if (selected instanceof LineTool)
            return selected.measurementLabel.content;

        return (selected as Participant).labelText;
    }

    constructor(
        private service: DiagramService,
        private crud: CRUDService,
        private selector: SelectionService,
        private ui: UIService
    ) {}

    async ngAfterViewInit() {
        await this.service.init(
            this.diagramCanvas,
            this.mapDiv,
            this.textboxesDiv,
            this.ellipsisMenu
        );
        this.ui.geolocMenu = this.geolocMenu;
        this.setUpDragHandlers();
        // this.fixMapTilesHack();
    }

    setUpDragHandlers() {
        this.diagramCanvas.ondragenter = () => {
            return false;
        };

        this.diagramCanvas.ondragover = (e: DragEvent) => {
            if (
                !e.dataTransfer ||
                e.dataTransfer.types.indexOf("icon/trailer") == -1
            ) {
                return false;
            }

            const rect = this.diagramCanvas.getBoundingClientRect();
            const canvasCorner = new paper.Point(rect.left, rect.top);
            const properPosition = paper.view.viewToProject(
                new paper.Point(e.x, e.y).subtract(canvasCorner)
            );

            this.hitchTarget = Trailer.getHitchTarget(properPosition);

            if (this.hitchTarget) {
                this.canHitchIcon.style.left = `${e.x - 25}px`;
                this.canHitchIcon.style.top = `${e.y - 100}px`;
                this.canHitchIcon.style.display = "block";
            } else {
                this.canHitchIcon.style.display = "none";
            }

            return false;
        };

        this.diagramCanvas.ondrop = async (e: DragEvent) => {
            if (!e.dataTransfer) {
                return;
            }

            if (e.dataTransfer.types.indexOf("application/icon") == -1) {
                return;
            }

            this.ui.dropSucceeded = true;

            const rect = this.diagramCanvas.getBoundingClientRect();
            const canvasCorner = new paper.Point(rect.left, rect.top);
            const properPosition = paper.view.viewToProject(
                new paper.Point(e.x, e.y).subtract(canvasCorner)
            );

            const iconData = e.dataTransfer.getData(
                "application/icon"
            ) as string;
            const iconNumber = parseInt(iconData);

            let newObject: DiagramObject;

            if (iconNumber) {
                const builder = new ParticipantBuilder<
                    Vehicle | NonMotorist | Trailer
                >(NonMotorist);
                builder.icon = iconData;

                switch (iconData) {
                    case IconId.Bicycle:
                        builder.subtype = "bicycle";
                        builder.scalable = true;
                        break;
                    case IconId.Pedestrian:
                        builder.subtype = "pedestrian";
                        builder.orientation = "Standing";
                        builder.scalable = true;
                        break;
                    case IconId.UtilityTrailer:
                        builder.childClassConstructor = Trailer;
                        break;
                    case IconId.Wheelchair:
                        builder.subtype = "wheelchair";
                        builder.scalable = true;
                        break;
                    case IconId.ATV:
                    case IconId.GolfCart:
                    case IconId.Motorcycle:
                    case IconId.LowSpeedVehicle:
                        builder.scalable = true;
                        builder.childClassConstructor = Vehicle;
                        break;
                    default:
                        builder.childClassConstructor = Vehicle;
                }

                newObject = await builder.build(properPosition);

                if (this.hitchTarget && newObject instanceof Trailer) {
                    newObject.attachTo(this.hitchTarget);
                    this.hitchTarget = undefined;
                }
            } else if (
                iconData.startsWith("Sign_") ||
                iconData.startsWith("Arrow_") ||
                iconData.startsWith("Object_")
            ) {
                const builder = new SignAndObjectBuilder();
                builder.iconFolder =
                    iconData.startsWith("Sign_") ||
                    iconData.startsWith("Arrow_")
                        ? "Signage"
                        : "Objects";

                newObject = await builder.build(properPosition, iconData);
            } else {
                // this is an animal
                const animalBuilder = new ParticipantBuilder(NonMotorist);
                animalBuilder.iconFolder = "Animals";
                animalBuilder.scalable = true;
                animalBuilder.icon = iconData;
                animalBuilder.label = iconData;
                animalBuilder.subtype = "animal";
                newObject = await animalBuilder.build(properPosition);
            }

            this.canHitchIcon.style.display = "none";
            DiagramObject.select(newObject);

            const objectAddition = DiagramAction.currentAction as
                | DiagramElementAction
                | undefined;

            if (objectAddition) {
                objectAddition.stopRecording(newObject);
            }

            return false; // (for the event)
        };
    }

    async onEllipsisMenuItemSelect(e: ContextMenuSelectEvent) {
        const selection = DiagramObject.selectedObject;
        if (!selection) return;

        let shouldSave = true;

        switch (e.item.data) {
            case "color":
                if (selection instanceof Annotation) {
                    selection.changeStrokeColor(
                        e.item.text === "None"
                            ? null
                            : new paper.Color(e.item.rgb)
                    );
                } else if (
                    selection instanceof Participant &&
                    selection.color !== e.item.colorCode
                ) {
                    selection.changeColor(e.item.colorCode);
                }
                break;
            case "fill":
                (selection as GeometricShape).changeFillColor(
                    e.item.text === "None" ? null : new paper.Color(e.item.rgb)
                );
                break;
            case "arrow color":
                (selection as Participant).arrowColor = e.item.colorCode;
                break;
            case "orientation":
                const firstWord = e.item.text.split(new RegExp("\\s|/"))[0];
                if (
                    selection instanceof Participant &&
                    selection.orientation !== firstWord
                ) {
                    selection.changeOrientation(firstWord);
                }
                break;
            case "ghost":
                if (selection instanceof Participant) {
                    selection.addPosition(e.item.dir || selection.moment);
                }
                break;
            case "opacity":
                const newOpacity = parseFloat(e.item.text) / 100;
                (selection as Participant).opacity = newOpacity;
                break;
            case "reverse":
                (selection as Participant).reverse();
                break;
            case "scaling":
                (selection as Participant).toggleScaling();
                break;
            case "size reset":
                (selection as CircleSelectedObject).resetScale();
                break;
            case "line style":
                (selection as Annotation).changeStrokeStyle(e.item.text);
                break;
            case "line weight":
                (selection as Annotation).changeStrokeWidth(
                    parseInt(e.item.text)
                );
                break;
            case "add node":
                ((selection as LineTool) || ParkingStallTool).addNode();
                break;
            case "stall angle":
                (selection as ParkingStallTool).changeStallAngle(e.item.text);
                break;
            case "arrows":
                (selection as LineTool).toggleArrow(e.item.text.split(" ")[0]);
                break;
            case "delete":
                this.selector.removeSelection();
                break;
            case "reposition":
                // revokeUndoable();
                this.geolocMenu.repositionCrashPoint(e.item.wholeDiagram);
                break;
            case "font size":
                (selection as TextboxTool).changeFontSize(
                    parseInt(e.item.text)
                );
                break;
            case "select all positions":
                this.selector.selectAllPositions(selection as Participant);
                break;
            case "font color":
                (selection as TextboxTool).changeFontColor(
                    e.item.text === "None" ? null : new paper.Color(e.item.rgb)
                );
                break;
            case "bold":
                (selection as TextboxTool).toggleBold();
                break;
            case "reset arrow":
                (selection as Participant).resetArrows();
                break;
            case "swap":
                e.originalEvent.stopPropagation();
                this.animalSwap.open(
                    paper.view.projectToView(
                        (selection as DiagramObject).ellipsisButton.bounds
                            .topRight
                    ),
                    "bottom left"
                );

                break;
            case "phantom":
                // CdtDiagram.togglePhantomAppearance(selection);
                break;
            case "loc info":
                this.locInfo.open(
                    paper.view.projectToView(
                        (selection as CircleSelectedObject).globalPointFor(
                            "bounds.topRight"
                        )
                    ),
                    "bottom left"
                );
                break;
        }

        // if (shouldSave) {
        //     this.crud.saveDiagram();
        // }
    }

    onKeyLabel(e: KeyboardEvent) {
        if (e.key === "Enter") {
            const selected = DiagramObject.selectedObject;
            const input = e.target as HTMLInputElement;
            if (selected instanceof LineTool) {
                // makeUndoable();
                selected.overrideMeasurement(input.value);
                this.crud.saveDiagram();
            } else if (selected instanceof Participant) {
                // makeUndoable();
                selected.changeLabel(input.value);
                this.crud.saveDiagram();
            }
            this.ellipsisMenu.hide();
        }

        e.stopPropagation();
    }

    get animalIcons() {
        return animalsIcons;
    }

    async swapIcon(icon: string) {
        if (this.selection) {
            this.selection.swapIcon(icon);
            this.animalSwap.close();
        }
    }

    @HostListener("save-requested")
    saveDiagram() {
        this.crud.saveDiagram();
    }

    @HostListener("menu-updated")
    flagMenuUpdate() {
        this.menuItemsHaveChanged = true;
    }
}
