import { Injectable } from "@angular/core";
import { CrashReportGridComponent } from "src/app/crash-report-grid/crash-report-grid.component";
import { GeolocPointMenuComponent } from "src/app/geoloc-point-menu/geoloc-point-menu.component";
import { HelpBarComponent } from "src/app/help-bar/help-bar.component";

@Injectable({ providedIn: "root" })

export class UIService {
    datagridHeight = 200;
    isDataGridCollapsed = true;
    isCropping = false;
    isRepositioning = false;
    sidePanelOpen = false;
    windowWidth = window.innerWidth;
    layerOpacity: number;
    gridDisabled = false;
    controlButtonsVisible = true;
    showGeolocWindow = false;
    legendIsOpen = false;
    legend: CrashReportGridComponent;
    helpBar: HelpBarComponent;
    static disableCount = 0;
    dropSucceeded = false;
    geolocMenu: GeolocPointMenuComponent;

    /** Disables the page and displays a pop-up message box until the user clicks one of the buttons
     * @returns A Promise which resolves with the text of the button the user ends up clicking
     */
    get padding(): L.FitBoundsOptions {
        let top = 5;
        let left = this.sidePanelOpen ? 267 : 5;
        let bottom = 5;
        let right = 5;
        let padding: L.FitBoundsOptions = {
            paddingTopLeft: [left, top],
            paddingBottomRight: [right, bottom]
        };
        return padding;
    }

    get isMobileOrTablet(): boolean { return window.innerWidth < 900; }
}
