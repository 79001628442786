import {
    DiagramLayer,
    ProjectLayer,
    getLayer,
    lockLayers,
    unlockLayers
} from "../../shared/layer";
import { Cursor, clearCursor, setCursor } from "../../shared/cursor";
import * as paper from "paper";
import { disableMapDragging, enableMapDragging } from "src/app/shared/diagram-map";
import { Annotation } from "./Annotation";
import { CRUDService } from "src/app/shared/services/crud.service";
import { DiagramObjectBuilder } from "../ObjectBuilders";
import { AnnotationNode } from "./AnnotationNode";
import { RotationNode } from "./GeometricShape";
import { DiagramObject } from "../DiagramObject";
import { DiagramElementAction } from "src/app/shared/Action";
import { DiagramService } from "src/app/shared/services/diagram.service";
import { TextboxTool } from "./TextboxTool";

export interface HandlerSet  {
    mousedown?: Function;
    mousemove?: Function;
    mouseup?: Function;
}

export abstract class AnnotationBuilder extends DiagramObjectBuilder {
    protected unfinishedAnno?: paper.Group;
    protected activeHandlers: HandlerSet = {};
    public isToggled = false;
    protected shapeCompleted = false;

    protected abstract initialLayer: DiagramLayer;
    protected projectLayer = ProjectLayer.main;

    /** This is called on the first mouse down. Create the starting shape named "shape" then pass it
     * to the {@link AnnotationBuilder.initAnno | initUnfinishedAnno() } method.
     * If additional properties need to be applied to unfinishedAnno, use the returned reference to avoid
     * TypeScript complaining of it being "possibly undefined."
     * @example
     * const rectangle = new paper.Path.Rectangle({
     *     from: point,
     *     to: point.add(1),
     *     name: "shape",
     *     strokeCap: "round"
     * });
     * const unfinishedAnno = this.initAnno(rectangle);
     * unfinishedAnno.data.startPoint = point;
     */
    protected abstract startShape(point: paper.Point): void;
    /** This is added to activeHandlers after the first mouse down */
    protected abstract toolMove: (e: paper.MouseEvent) => void;
    /** This is called on all mouse down events after the first one.
     * @todo If this completes the shape, apply any subclass specific logic before calling the shared
     * {@link AnnotationBuilder.completeShape | completeShape() } method
     */
    protected abstract continueShape(point: paper.Point): void;
    /** Return an array of the initial resizing nodes. This is called automatically by
     * the {@link AnnotationBuilder.completeShape | completeShape() } method; Simply implement
     * as needed for the specific subclass, but don't worry about calling it */
    // protected abstract createResizeNodes(): paper.Path.Circle[];
    protected abstract createResizeNodes(): paper.Path.Circle[];
    /** This is called automatically by the
     * {@link AnnotationBuilder.completeShape | completeShape() } method.
     * @todo Create an instance of the specific subclass and call
     * any part-positioning methods needed, then return it.
     * @example
     * const rectangle = new RectangleTool(this.unfinishedAnno!);
     * rectangle.repositionRotationNode();
     * return rectangle;
     * */
    public abstract build(): Annotation;

    constructor(protected btn: HTMLButtonElement) {
        super();
    }

    protected get shape(): paper.Path {
        return this.unfinishedAnno?.children['shape'];
    }

    protected setDefaultStyles(shape: paper.Path) {
        shape.strokeColor = Annotation.defaultStrokeColor;
        shape.strokeWidth = Annotation.defaultStrokeWidth;
        shape.fillColor = Annotation.defaultFillColor;
        shape.dashArray = Annotation.defaultDashArray;
    }

    /** sets default styles on the shape and creates the unfinishedAnno group.
     * @returns A reference to this.unfinishedAnno
     */
    protected initAnno(shape: paper.Path) {
        this.setDefaultStyles(shape);
        this.preventInvisibleShape(shape);
        this.unfinishedAnno = new paper.Group([shape]);
        return this.unfinishedAnno;
    }

    protected toolDown = (e: paper.MouseEvent) => {
        if (this.unfinishedAnno) {
            this.continueShape(e.point);
        } else {
            this.startShape(e.point);
            paper.view.on('mousemove', this.toolMove);
            this.activeHandlers.mousemove = this.toolMove;
        }
        e.stopPropagation(); // without this, it would bubble up to window and cause cleanUp()
    };

    /** Returns app to pre-toggled state; For cancelling or after completing a drawing. */
    private cleanUp = () => {
        this.isToggled = false;
        this.unfinishedAnno = undefined;
        paper.view.off(this.activeHandlers);
        this.activeHandlers = {};
        clearCursor();
        unlockLayers();
        TextboxTool.unlockTextboxes();
        getLayer(DiagramLayer.main).activate();
        this.btn.blur();
        this.btn.classList.remove('toggled');
        enableMapDragging();
        window.removeEventListener('mousedown', this.cleanUp);
        window.removeEventListener('keydown', this.escKeyCleanUp);
        const addAction = DiagramElementAction.currentAction;
        if (this.shapeCompleted) {
            addAction?.stopRecording();
        } else {
            addAction?.cancel();
        }
    };

    private makeResizeNodeGroup() {
        this.unfinishedAnno?.addChild(
            new paper.Group({
                children: this.createResizeNodes(),
                name: 'nodes',
                visible: false,
            })
        );
    }

    /** Call this to run all the shared finalization routines */
    protected completeShape() {
        this.addEllipsisButton(this.unfinishedAnno!);
        this.makeResizeNodeGroup();
        const newAnno = this.build();
        DiagramObject.select(newAnno);
        this.shapeCompleted = true;
        this.cleanUp();
    }

    /** TextboxBuilder should override this to allow a temporary border, but not require any stroke or fill once completed
     * @example super.preventInvisibleShape();
     * this.hasTempBorder = true;
     */
    protected preventInvisibleShape(shape: paper.Path) {
        if (
            !Annotation.defaultStrokeColor &&
            (!Annotation.defaultFillColor ||
                Annotation.defaultFillColor.equals(Annotation.nearlyInvisible))
        ) {
            shape.strokeColor = new paper.Color('black');
            return true;
        }

        return false;
    }

    /** Override if something other than { mousedown: this.toolDown } is needed */
    readonly initialHandlers: HandlerSet = { mousedown: this.toolDown };

    /** Preps the tool for use, or cancels it */
    toggle() {
        if (this.isToggled) {
            this.unfinishedAnno?.remove();
            this.cleanUp();
            // revokeUndoable();
            return;
        }

        // makeUndoable();
        this.shapeCompleted = false;
        new DiagramElementAction({name: "add annotation"}).startRecording();
        this.isToggled = true;
        this.btn.classList.add('toggled');
        disableMapDragging();
        setCursor(Cursor.Crosshair);
        lockLayers();
        TextboxTool.lockTextboxes();
        window.addEventListener('mousedown', this.cleanUp);
        window.addEventListener('keydown', this.escKeyCleanUp);
        // activateProjectLayer(this.projectLayer);
        getLayer(this.initialLayer).activate();
        this.activeHandlers = this.initialHandlers;
        paper.view.on(this.activeHandlers);
    }

    private escKeyCleanUp = (e: KeyboardEvent) => {
        if (['Escape', 'Delete', 'Backspace'].includes(e.key)) {
            this.cleanUp();
        }
    };
}

export abstract class GeometricShapeBuilder extends AnnotationBuilder {
    get stopNode(): paper.Path.RegularPolygon {
        // if (
        //     !this.unfinishedAnno ||
        //     !this.unfinishedAnno.children['stop node']
        // ) {
        //     throw new Error(
        //         'No stop node created, or unfinishedAnno undefined'
        //     );
        // }

        return this.unfinishedAnno!.children['stop node'];
    }

    protected continueShape(point: paper.Point): void {
        if (!this.unfinishedAnno) return;
        paper.view.off(this.activeHandlers);
        this.unfinishedAnno.applyMatrix = false;
        this.addRotationNode();
        this.completeShape();
    }

    protected addRotationNode() {
        const rotationNode = new RotationNode();

        if(this.unfinishedAnno)  rotationNode.addTo(this.unfinishedAnno);
    }

    public makeNodesAlongShape<NodeType extends AnnotationNode>(
        nodeConstructor: new (point: paper.Point, additionalAttributes: any) => NodeType,
        onEdges = true
    ): paper.Path.Circle[] {
        const nodes = new Array<paper.Item>();

        for (const seg of this.shape.segments) {
            nodes.push(
                new nodeConstructor(seg.point, {
                    data: {
                        segment: seg.index,
                        position: 'corner',
                        cursor: 'dynamic',
                    },
                }).entireObject
            );

            if (onEdges) {
                nodes.push(
                    new nodeConstructor(seg.curve.getPointAtTime(0.5), {
                        data: {
                            segment: seg.index,
                            position: 'edge',
                            cursor: 'dynamic',
                        },
                    }).entireObject
                );
            }
        }

        return nodes as paper.Path.Circle[];
    }

    protected createStopNode(p: paper.Point) {
        return new paper.Path.RegularPolygon({
            center: p,
            sides: 8,
            radius: 8,
            fillColor: 'red',
            name: 'stop node',
            strokeWidth: 2,
        });
    }
}
