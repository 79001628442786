import { GeometricShape } from './GeometricShape';
import * as paper from 'paper';
import { GeometricShapeBuilder, HandlerSet } from './AnnotationBuilders';
import { DiagramLayer, getLayer } from 'src/app/shared/layer';
import { LineTool, LineToolBuilder } from './LineTool';
import { Annotation } from './Annotation';
import { MenuItem } from '@progress/kendo-angular-menu';

export class FreeHandToolBuilder extends GeometricShapeBuilder {
    private isClosed: boolean = false;
    private tempBorder = false;
    protected initialLayer: DiagramLayer =
        DiagramLayer.foregroundAnnotations;

    protected toolUp = (e: paper.MouseEvent) => {
        if (!this.unfinishedAnno) return;

        paper.view.off(this.activeHandlers);
        this.shape.add(e.point);

        if (this.shape.segments.length < 3) {
            this.toggle();
            return;
        }

        this.shape.simplify(10);

        if (this.shape.segments.length < 3) {
            for (const segment of this.shape.segments) {
                segment.clearHandles();
            }
        }

        this.shape.strokeWidth = Annotation.defaultStrokeWidth;

        if (this.stopNode.contains(e.point)) {
            this.shape.closePath();
            this.stopNode.remove();
            this.shape.fillColor = Annotation.defaultFillColor;
            getLayer(DiagramLayer.backgroundAnnotations).activate();
            this.unfinishedAnno.addTo(paper.project.activeLayer);
            this.isClosed = true;
            this.continueShape(e.point);
        } else {
            this.stopNode.remove();
            new LineToolBuilder(
                this.btn,
                'curve',
                this.unfinishedAnno,
                this.activeHandlers,
                e.point
            );
        }
    };

    readonly initialHandlers: HandlerSet = {
        mousedown: this.toolDown,
        mouseup: this.toolUp,
    };

    protected startShape(point: paper.Point): void {
        const freehand = new paper.Path({
            from: point,
            to: point.add(1),
            name: 'shape',
            strokeColor: Annotation.defaultStrokeColor
                ? Annotation.defaultStrokeColor
                : new paper.Color("black"),
            strokeWidth: Annotation.defaultStrokeColor
                ? Annotation.defaultStrokeWidth
                : 1,
            dashArray: Annotation.defaultDashArray,
            strokeCap: 'round',
            data: {
                thing: 'annotation freehand',
                category: 'annotation',
            },
        });
        this.unfinishedAnno = this.initAnno(freehand);
        this.unfinishedAnno.addChild(this.createStopNode(point));
    }

    protected setDefaultStyles(shape: paper.Path): void {
        super.setDefaultStyles(shape);
        shape.fillColor = null;
    }

    protected preventInvisibleShape(shape: paper.Path): boolean {
        if (!Annotation.defaultStrokeColor) {
            shape.strokeColor = new paper.Color("black");
            this.tempBorder = true;
            return true;
        }

        return false;
    }

    protected toolMove = (e: paper.MouseEvent) => {
        if (!this.unfinishedAnno) return;

        this.shape.add(e.point);

        if (!this.stopNode.strokeColor && this.stopNode.contains(e.point)) {
            this.stopNode.strokeColor = new paper.Color('white');
        } else if (
            this.stopNode.strokeColor &&
            !this.stopNode.contains(e.point)
        ) {
            this.stopNode.strokeColor = null;
        }
    };

    protected createResizeNodes(): paper.Path.Circle[] {
        return [];
    }

    build(): Annotation {
        if (this.isClosed) {
            if (this.tempBorder) {
                this.shape.strokeColor = null;
            }

            const freeHandTool = new GeometricShape(this.unfinishedAnno!);
            freeHandTool.repositionRotationNode();
            return freeHandTool;
        }

        return new LineTool(this.unfinishedAnno!);
    }
}
