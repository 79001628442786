import { environment } from "./../../environments/environment";

interface ControlBarButton {
    label: string;
    icon: string;
    disabled?: boolean;
}
export const doneButton: ControlBarButton = {
    label: "Finish",
    icon: "fal fa-check fa-fw clr-success"
};

export var controlBarButtons: Array<Array<ControlBarButton>> = [
    [
        { label: "Select", icon: "select-background" },
        { label: "Select All", icon: "select-all-background" },
        { label: "Undo", icon: "fal fa-undo fa-fw clr-light", disabled: true },
        { label: "Redo", icon: "fal fa-redo fa-fw clr-light", disabled: true },
        { label: "Reset", icon: "fal fa-repeat fa-fw clr-light" }
    ],
    [doneButton],
    [
        //Temporarily disabled
        // { label: 'Report', icon: 'fal fa-bug fa-fw  clr-light' },
        { label: "Export", icon: "download-background" },
        { label: "Data", icon: "fal fa-info-circle fa-fw  clr-light" },
        { label: "Help", icon: "fal fa-question-circle fa-fw  clr-light" }
    ]
];

export const confirmOrCancelButtons: ControlBarButton[][] = [
    [{ label: "Back", icon: "fal fa-long-arrow-left fa-fw clr-light" }],
    [{ label: "Done", icon: "fal fa-check fa-fw clr-success" }],
    [{ label: "Help", icon: "fal fa-question-circle fa-fw  clr-light" }]
];

export function disableButton(buttonId: string) {
    if (buttonId == doneButton.label) {
        doneButton.disabled = true;
        return;
    }

    const btn = controlBarButtons.flat().find((value) => {
        return value.label == buttonId;
    });

    if (btn) {
        btn.disabled = true;
    }
}

export function enableButton(buttonId: string) {
    if (buttonId == doneButton.label) {
        doneButton.disabled = false;
        return;
    }

    const btn = controlBarButtons.flat().find((value) => {
        return value.label == buttonId;
    });

    if (btn) {
        btn.disabled = false;
    }
}
