import { Injectable } from "@angular/core";
import { CrashReportService } from "./crash-report.service";
import {
    finishMajorDraw,
    promptBox,
    startMajorDraw
} from "src/app/page-disabler/disable-enable-page";
import { UIService } from "./ui.service";
import * as Models from "../../cdt-diagram/cdt-diagram-models";
import * as map from "../diagram-map";
import { getDiagramBounds, oppositeOrientation } from "../helpers";
import { ParticipantBuilder } from "src/app/classes/ObjectBuilders";
import { NonMotorist } from "src/app/classes/NonMotorist";
import { IconId } from "../icon";
import { Vehicle } from "src/app/classes/Vehicle";
import { Participant } from "src/app/classes/Participant";
import { Trailer } from "src/app/classes/Trailer";
import * as paper from "paper";
import { DiagramService } from "./diagram.service";

@Injectable({
    providedIn: "root"
})
export class DiagramAutomationService {
    private readonly animalBuilder = new ParticipantBuilder(NonMotorist);
    private readonly trailerBuilder = new ParticipantBuilder(Trailer);

    get crashPoint() {
        return DiagramService.crashPointObject.position;
    }

    constructor(
        private crashReport: CrashReportService,
        private ui: UIService
    ) {
        this.animalBuilder.colorCode = "GRN";
        this.animalBuilder.icon = "Alligator";
        this.animalBuilder.label = "Animal";
        this.trailerBuilder.pivotLocked = true;
        this.trailerBuilder.icon = IconId.UtilityTrailer;
    }

    /** Uses the crashReportData and the participants array from cdt-diagram-read-write.ts to place
     *  up to the first 2 participants on the diagram */
    async placeParticipants() {
        if (!this.crashReport.participants.length) {
            return;
        }

        startMajorDraw();

        let participantIndex = 0; // index of the participant array corresponding to participant in question
        let participantsPlaced = 0;
        let animalCrash = false;
        let maxVehiclesToPlace = 2;

        // If this is a animal crash, place 1st vehicle and the animal
        // If this is a ped or bicycle crash, place 1st vehicle and 1st ped
        if (this.crashReport.crashReportData.firstHarmfulEvent) {
            if (
                this.crashReport.crashReportData.firstHarmfulEvent ==
                Models.FirstHarmfulEvent.Animal
            ) {
                animalCrash = true;
                maxVehiclesToPlace = 1;
            } else if (
                this.crashReport.crashReportData.firstHarmfulEvent ==
                    Models.FirstHarmfulEvent.Pedestrian ||
                this.crashReport.crashReportData.firstHarmfulEvent ==
                    Models.FirstHarmfulEvent.Pedalcycle
            ) {
                maxVehiclesToPlace = 1;
            }
        }

        const promises = new Array<Promise<any>>();
        const skippedParticipantIndices = new Array<number>();

        // loop through the vehicles up to one of the limits, placing them on the diagram
        if (this.crashReport.crashReportData.vehicles) {
            for (
                let i = 0;
                i < this.crashReport.crashReportData.vehicles.length &&
                participantsPlaced < maxVehiclesToPlace;
                ++i, ++participantIndex
            ) {
                const veh = this.crashReport.crashReportData.vehicles[i];
                if (
                    // do not place if travel direction or manuever is not provided
                    veh.travelDirection &&
                    veh.maneuver &&
                    veh.maneuver != Models.VehicleManeuver.NotProvided &&
                    !Models.unknownDirs.includes(
                        veh.travelDirection.toUpperCase()
                    )
                ) {
                    ++participantsPlaced;
                    promises.push(
                        this.placeVehicle(
                            this.crashReport.crashReportData.vehicles[i],
                            participantIndex
                        )
                    );
                } else {
                    skippedParticipantIndices.push(participantIndex);
                }
            }

            participantIndex = this.crashReport.crashReportData.vehicles.length; // if any vehicles were skipped, make sure next index is a NM.
        }

        // loop through the non-motorists up to one of the limits, placing them on the diagram
        if (this.crashReport.crashReportData.nonMotorists) {
            if (!animalCrash) {
                for (
                    let i = 0;
                    i < this.crashReport.crashReportData.nonMotorists.length &&
                    participantsPlaced < 2;
                    ++i, ++participantIndex
                ) {
                    const nm = this.crashReport.crashReportData.nonMotorists[i];
                    if (
                        // Do not place if not explicity a pedestrian or cyclist.
                        nm.desc &&
                        [
                            Models.NonMotoristDescription.Pedestrian,
                            Models.NonMotoristDescription.OtherPedestrian,
                            Models.NonMotoristDescription.Bicyclist,
                            Models.NonMotoristDescription.OtherCyclist
                        ].includes(nm.desc)
                    ) {
                        ++participantsPlaced;
                        promises.push(
                            this.placeNonMotorist(nm, participantIndex)
                        );
                    } else {
                        skippedParticipantIndices.push(participantIndex);
                    }
                }
            }
        }

        if (animalCrash) {
            promises.push(this.placeAnimal());
        }

        await Promise.all(promises);
        finishMajorDraw();

        map.goHome(
            { ...this.ui.padding, animate: true, duration: 1 },
            getDiagramBounds(50)
        );

        const numParticipants = this.crashReport.participants.length;

        if (participantsPlaced == 2 && numParticipants > 2) {
            const plurality = numParticipants - 2 > 1 ? "s" : "";
            await promptBox(
                `Alert - ${
                    numParticipants - 2
                } Participant${plurality} Not Placed`,

                `Two crash participants ` +
                    `have been placed on the diagram for you. ` +
                    `The remaining ${
                        numParticipants - 2
                    } participant${plurality} must be added manually by using the checkboxes in the ` +
                    `Reported Participants Legend (bottom left of map).`
            );
            this.ui.legendIsOpen = true;
            this.ui.legend.flashRows(
                Array.from({ length: numParticipants - 2 }, (v, k) => k + 2)
            );
        } else if (participantsPlaced < numParticipants) {
            const numSkipped = skippedParticipantIndices.length;
            const plurality = numSkipped > 1 ? "s" : "";
            await promptBox(
                `Alert - ${numSkipped} Participant${plurality} Not Placed`,
                `${numSkipped} participant${plurality} ${
                    numSkipped > 1 ? "were" : "was"
                } ` +
                    "not automatically added to the diagram because at least one necessary attribute was not filled out on the crash report. " +
                    "To add " +
                    `${
                        numSkipped > 1 ? "them" : "it"
                    } to the diagram manually, check the box${
                        numSkipped > 1 ? "es" : ""
                    } ` +
                    "in the Reported Participants Legend (bottom left of map)."
            );
            this.ui.legendIsOpen = true;
            this.ui.legend.flashRows(skippedParticipantIndices);
        }
    }

    /** Given a non-motorist description, returns the data.thing string that should be used for the diagram object core */
    private determineNonMotoristType(
        desc: Models.NonMotoristDescription | undefined
    ): string {
        if (desc) {
            switch (desc) {
                case Models.NonMotoristDescription.Pedestrian:
                case Models.NonMotoristDescription.OtherPedestrian:
                    return "pedestrian";
                case Models.NonMotoristDescription.Bicyclist:
                case Models.NonMotoristDescription.OtherCyclist:
                    return "bicycle";
            }
        }

        return "pedestrian";
    }

    private async placeAnimal() {
        const animal = await this.animalBuilder.build(this.crashPoint);
        animal.subtype = "animal";
    }

    /** Places a non-motorist on the diagram
     * @param nm the data that describes this non-motorist
     * @param participantIndex should be the index of the participants array that corresponds to this participant
     */
    async placeNonMotorist(
        nm: Models.NonMotoristData,
        participantIndex: number
    ) {
        startMajorDraw();

        const nmBuilder = new ParticipantBuilder(NonMotorist);
        nmBuilder.scalable = true;
        const subtype = this.determineNonMotoristType(nm.desc);

        const newNm = await nmBuilder.build(
            subtype == "pedestrian"
                ? this.crashPoint.add(new paper.Point(0, 15))
                : this.crashPoint,
            this.crashReport.participants[participantIndex].icon,
            "NM" + nm.number
        );

        newNm.subtype = subtype;
        newNm.participantIndex = participantIndex;
        newNm.participantData = this.crashReport.participants[participantIndex];
        this.crashReport.participants[participantIndex].isDiagrammed = true;

        finishMajorDraw();
        return newNm;
    }

    /** Places a vehicle and its prior position (ghost) on the diagram
     * @param veh the data that describes this vehicle
     * @param participantIndex should be the index of the participants array that corresponds to this participant
     */
    async placeVehicle(veh: Models.VehicleData, participantIndex: number) {
        startMajorDraw();

        let iconOrientation =
            veh.impactArea == Models.VehicleImpactArea.Overturn
                ? "Bottom"
                : "Top";
        if (
            // motorcycle does not have bottom-up.
            iconOrientation == "Bottom" &&
            this.crashReport.participants[participantIndex].icon ==
                IconId.Motorcycle
        ) {
            iconOrientation = "Side";
        }

        const newVeh = await new ParticipantBuilder(Vehicle).build(
            new paper.Point(0, 0),
            this.crashReport.participants[participantIndex].icon,
            "V" + veh.number,
            veh.colorCode && Models.ncicColorCodes.hasOwnProperty(veh.colorCode)
                ? veh.colorCode
                : "SIL",
            iconOrientation,
            veh.isHitAndRun === "Y"
        );

        // give the object core a participantIndex for interaction w/ the data grid
        newVeh.participantIndex = participantIndex;
        newVeh.participantData =
            this.crashReport.participants[participantIndex];

        let orientation: string | undefined = undefined; // this is the orientation that will be used for automated placement of this object and its ghost

        if (veh.travelDirection) {
            switch (veh.travelDirection.toUpperCase()) {
                case "E":
                case "EAST":
                    newVeh.rotate(90);
                    orientation = "E";
                    break;
                case "S":
                case "SOUTH":
                    newVeh.rotate(180);
                    orientation = "S";
                    break;
                case "W":
                case "WEST":
                    newVeh.rotate(-90);
                    orientation = "W";
                    break;
                default:
                    // case "N":
                    // case "NORTH":
                    orientation = "N";
                    break;
            }
        }

        if (veh.impactArea) {
            // temporarily set pivot to the point of impact so that placement is offset by this point
            newVeh.entireObject.pivot = this.getPointOfImpact(
                newVeh,
                veh.impactArea,
                (veh.maneuver &&
                    orientation &&
                    veh.maneuver == Models.VehicleManeuver.Backing) ||
                    veh.maneuver == Models.VehicleManeuver.UTurn
                    ? oppositeOrientation(orientation)
                    : orientation
            );
        } else {
            newVeh.entireObject.pivot = this.getPointOfImpact(
                newVeh,
                Models.VehicleImpactArea.Front,
                orientation
            );
        }

        newVeh.position = this.crashPoint;
        // reset pivot to the center of the vehicle icon
        newVeh.entireObject.pivot = newVeh.globalPointFor("position");

        // if this vehicle has a maneuver that can be automated, automate it
        if (veh.maneuver) {
            if (veh.maneuver === Models.VehicleManeuver.Backing) {
                newVeh.reverse();
            }
            if (
                veh.maneuver != Models.VehicleManeuver.Parked &&
                veh.maneuver != Models.VehicleManeuver.StoppedInTraffic
            ) {
                if (
                    veh.maneuver === Models.VehicleManeuver.Backing ||
                    veh.maneuver === Models.VehicleManeuver.UTurn
                ) {
                    newVeh.rotate(180);
                }

                const ghost = newVeh.addPosition("Prior");
                this.positionGhost(
                    ghost,
                    veh.maneuver,
                    veh.impactArea,
                    orientation
                );
                if (veh.impactArea == Models.VehicleImpactArea.Overturn) {
                    ghost.changeOrientation("Top");
                }
            }
        }

        this.crashReport.participants[participantIndex].isDiagrammed = true;

        // if the area of initial impact = "Trailer" create and attach a trailer
        if (veh.impactArea == Models.VehicleImpactArea.Trailer) {
            const trailer = await this.trailerBuilder.build(
                Trailer,
                new paper.Point(0, 0)
            );

            newVeh.entireObject.pivot = newVeh.core.localToGlobal(
                new paper.Point(
                    newVeh.core.bounds.bottomCenter.x,
                    newVeh.core.bounds.bottomCenter.y +
                        trailer.core.bounds.height
                )
            );
            newVeh.position = this.crashPoint;
            newVeh.entireObject.pivot = newVeh.globalPointFor("position");
            trailer.attachTo(newVeh);
        }

        newVeh.updateArrows();
        finishMajorDraw();

        return newVeh;
    }

    /** Positions the prior ghost of a vehicle based on the provided manuever and orientation of the vehicle */
    private positionGhost(
        ghost: Participant,
        maneuver: Models.VehicleManeuver,
        impact: Models.VehicleImpactArea | undefined,
        orientation: string | undefined
    ) {
        switch (maneuver) {
            case Models.VehicleManeuver.TurningLeft:
                ghost.rotate(90);
                this.shiftTowards("left", ghost, orientation, 200);
                this.shiftTowards("up", ghost, orientation, 70);
                break;
            case Models.VehicleManeuver.UTurn:
                ghost.rotate(180);
                this.shiftTowards("right", ghost, orientation, 75);
                this.shiftTowards("down", ghost, orientation, 186);
                break;
            case Models.VehicleManeuver.TurningRight:
                ghost.rotate(-90);
                this.shiftTowards("right", ghost, orientation, 140);
                this.shiftTowards("up", ghost, orientation, 70);
                break;
            case Models.VehicleManeuver.ChangingLanes:
                if (impact && orientation) {
                    if (impact >= 2 && impact <= 7) {
                        // guess would be changing from a lane on the left
                        this.shiftTowards("left", ghost, orientation, 30);
                    } else if (impact >= 9 && impact <= 14) {
                        this.shiftTowards("right", ghost, orientation, 30);
                    }
                }
                break;
            // case Models.VehicleManeuver.Backing:
            //     const ghostCore = getObjectCore(wholeGhostObject);
            //     // const arrow = getItemByRefId(ghostCore.data.arrowIds.out);
            //     // arrow.data.isReversed = true;
            //     // updateArrow(arrow);
            //     reverseArrowOfGhost(ghostCore);
            //     break;
            case Models.VehicleManeuver.Slowing:
                this.shiftTowards(
                    "up",
                    ghost,
                    orientation,
                    Math.max(
                        ghost.entireObject.bounds.height,
                        ghost.entireObject.bounds.width
                    )
                );
                break;
            case Models.VehicleManeuver.StraightAhead:
                // increase the default distance between positions
                this.shiftTowards(
                    "down",
                    ghost,
                    orientation,
                    Math.max(
                        ghost.entireObject.bounds.height,
                        ghost.entireObject.bounds.width
                    )
                );
                break;
        }
    }

    /** Translates object relative to its orientation, e.g. up = forward */
    private shiftTowards(
        dir: "left" | "right" | "up" | "down",
        ghost: Participant,
        orientation: string | undefined,
        amt = 50
    ) {
        if (orientation) {
            switch (orientation) {
                case "N":
                    if (dir == "left" || dir == "right") {
                        ghost.position.x += dir == "left" ? -1 * amt : amt;
                    } else {
                        ghost.position.y += dir == "up" ? -1 * amt : amt;
                    }
                    break;
                case "E":
                    if (dir == "left" || dir == "right") {
                        ghost.position.y += dir == "left" ? -1 * amt : amt;
                    } else {
                        ghost.position.x += dir == "up" ? amt : -1 * amt;
                    }
                    break;
                case "S":
                    if (dir == "left" || dir == "right") {
                        ghost.position.x += dir == "left" ? amt : -1 * amt;
                    } else {
                        ghost.position.y += dir == "up" ? amt : -1 * amt;
                    }
                    break;
                case "W":
                    if (dir == "left" || dir == "right") {
                        ghost.position.y += dir == "left" ? amt : -1 * amt;
                    } else {
                        ghost.position.x += dir == "up" ? -1 * amt : amt;
                    }
            }
        }
    }

    /** Returns a point on the icon of the item based on the provided area of initial impact and the vehicle's orientation */
    private getPointOfImpact(
        vehicle: Vehicle,
        impact: Models.VehicleImpactArea,
        orientation: string | undefined
    ): paper.Point {
        let impactVector = new paper.Point(vehicle.entireObject.position);

        let halfVehWidth = vehicle.core.bounds.height / 1.75; // well, maybe a little more than half
        let halfVehHeight = vehicle.core.bounds.width / 1.75;

        if (orientation == "E" || orientation == "W") {
            if (orientation == "E") {
                impactVector.angle = 0;
            } else {
                impactVector.angle = 180;
            }
        } else if (orientation == "N" || orientation == "S") {
            if (orientation == "N") {
                impactVector.angle = -90;
            } else {
                impactVector.angle = 90;
            }
        }

        switch (impact) {
            case Models.VehicleImpactArea.FrontRight:
                impactVector.angle = impactVector.angle + 25;
                impactVector.length = halfVehWidth;
                break;
            case Models.VehicleImpactArea.RightQ1:
                impactVector.angle = impactVector.angle + 35;
                impactVector.length = (halfVehHeight + halfVehWidth) / 2;
                break;
            case Models.VehicleImpactArea.RightQ2:
                impactVector.angle = impactVector.angle + 65;
                impactVector.length = halfVehHeight;
                break;
            case Models.VehicleImpactArea.RightQ3:
                impactVector.angle = impactVector.angle + 130;
                impactVector.length = halfVehHeight;
                break;
            case Models.VehicleImpactArea.RightQ4:
                impactVector.angle = impactVector.angle + 150;
                impactVector.length = (halfVehHeight + halfVehWidth) / 2;
                break;
            case Models.VehicleImpactArea.BackRight:
                impactVector.angle = impactVector.angle + 160;
                impactVector.length = halfVehWidth;
                break;
            case Models.VehicleImpactArea.Back:
                impactVector.angle = impactVector.angle + 180;
                impactVector.length = halfVehWidth;
                break;
            case Models.VehicleImpactArea.Trailer:
                impactVector.angle = impactVector.angle + 180;
                impactVector.length = halfVehWidth * 3;
                break;
            case Models.VehicleImpactArea.BackLeft:
                impactVector.angle = impactVector.angle - 160;
                impactVector.length = halfVehWidth;
                break;
            case Models.VehicleImpactArea.LeftQ4:
                impactVector.angle = impactVector.angle - 150;
                impactVector.length = (halfVehHeight + halfVehWidth) / 2;
                break;
            case Models.VehicleImpactArea.LeftQ3:
                impactVector.angle = impactVector.angle - 130;
                impactVector.length = halfVehHeight;
                break;
            case Models.VehicleImpactArea.LeftQ2:
                impactVector.angle = impactVector.angle - 65;
                impactVector.length = halfVehHeight;
                break;
            case Models.VehicleImpactArea.LeftQ1:
                impactVector.angle = impactVector.angle - 35;
                impactVector.length = (halfVehHeight + halfVehWidth) / 2;
                break;
            case Models.VehicleImpactArea.FrontLeft:
                impactVector.angle = impactVector.angle - 25;
                impactVector.length = halfVehWidth;
                break;
            case Models.VehicleImpactArea.Overturn:
                impactVector.length = 0;
                break;
            default:
                impactVector.length = halfVehWidth;
        }

        // impactVector.length =
        //     Math.abs(Math.cos(impactVector.angle)) * vehCore.bounds.height / 2 +
        //     Math.abs(Math.sin(impactVector.angle)) * vehCore.bounds.width / 2
        return impactVector;
    }
}
