import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DiagramLayer, getLayer } from "../layer";
import { LatLng } from "leaflet";
import Watchable from "@blakecanter/watchablejs";
import { promptBox } from "src/app/page-disabler/disable-enable-page";
import { QueryStringService } from "./query-string.service";
import { CRUDService } from "./crud.service";
import { contactSupportMessage } from "../global-messages";
// import { Cursor, setCursor } from "../cursor";
// import { DiagramService } from "./diagram.service";
// import * as paper from "paper";
// import { paperPointToLatLng } from "../diagram-map";
import { UIService } from "./ui.service";
import { Cursor } from "../cursor";

export type RelToNwk = "INTERSECTION" | "SEGMENT" | "OFFROADWAY" | "RAMP";

export interface GeocodingAddressInput {
    streetNbr?: string;
    streetNm?: string;
    cityOrPlace?: string;
    county?: string;
    stateShort?: string;
    postalCode?: string;
}

export interface GeoServiceLocation {
    LocationId: number;
    BaseMapVersion: string;
    GeoServiceVersion: string;
    CityCode: number;
    CityName: string;
    CountyCode: number;
    CountyName: string;
    DirectionOfTravel: string;
    Lane: string;
    Latitude: number;
    Longitude: number;
    MilepostNumber: number;
    NearestIntersectingStreetName: string;
    NearestIntersectingStreetNamesAlt: string[];
    NearestIntersectionId: number;
    NearestIntersectionName: string;
    NearestIntersectionOffsetDir: string;
    NearestIntersectionOffsetFeet: number;
    NearestSegmentId: number;
    OtherIntersectingStreetName: string;
    OtherIntersectingStreetNamesAlt: string[];
    OtherIntersectionId: number;
    OtherIntersectionName: string;
    OtherIntersectionOffsetDir: string;
    OtherIntersectionOffsetFeet: number;
    RoadSystemIdentifier: number;
    StateName: string;
    StreetAddressNumber: string;
    StreetName: string;
    StreetNamesAlt: string[];
    RelationshipToNetwork: RelToNwk;
    NearestIntersectingSegmentId: number;
    OtherIntersectingSegmentId: number;
    NearestIntersectionNodeLatitude: number;
    NearestIntersectionNodeLongitude: number;
}

@Injectable({
    providedIn: "root"
})
export class GeolocationService {
    readonly geoServiceKey = "KM2YJTkm4L";
    readonly geoService = environment.production
        ? "geolocation30"
        : "geolocation301dev";
    readonly geoServiceBase = `https://s4.geoplan.ufl.edu/${this.geoService}/GeolocationService.svc`;
    // export var locationFound = false;
    static geoServiceData: any;
    geoDataIsLoaded = new Watchable(false);
    originalLocation: GeoServiceLocation;
    originalLocationWasDirty = false;
    private static injectedInstance: GeolocationService;

    get geoServiceData() {
        return GeolocationService.geoServiceData;
    }

    set geoServiceData(data: any) {
        GeolocationService.geoServiceData = data;
    }

    get latitude(): number {
        return this.geoServiceData.Location.Latitude;
    }

    get longitude(): number {
        return this.geoServiceData.Location.Longitude;
    }

    constructor(
        private ids: QueryStringService,
        private crud: CRUDService,
        private ui: UIService
    ) {
        this.loadGeoServiceData();
        GeolocationService.injectedInstance = this;
    }

    /** Call this after paper is set up!
     *
     *  This checks if the location was updated back in the Geolocation Service
        in which case the location_dirty flag in the DB might = 'Y' even though it shouldn't.
     */
    correctLocationDirty() {
        // Comparing latitude and longitude should be enough to tell if there was a change...
        // However, this is risky due to rounding issues... but toFixed(12) should work since our database holds lat,lng to 12 decimal places
        const crashPointLayer = getLayer(DiagramLayer.crashPoint);
        if (
            crashPointLayer.data.lat == this.originalLocation.Latitude &&
            crashPointLayer.data.lng == this.originalLocation.Longitude
        ) {
            // if the lat and lng is the same, we need to check if the location was updated in this app, but then the browser closed
            // unexpectedly, in which case the location_dirty value will be 'Y'.
            this.getLocationDirty().then((value) => {
                this.originalLocationWasDirty = value == "Y" ? true : false;
            });
        } else {
            // if they are not the same, assume the location was updated outside this app: set location_dirty to 'N'
            this.setLocationDirty("N");
            // and update the crash point layer's saved latLng
            this.saveLatLngToCrashPointLayer(this.originalLocation);
            this.crud.saveDiagram({ diagram: true }); // and save it to the database so it sticks
        }
    }

    saveLatLngToCrashPointLayer(loc: GeoServiceLocation) {
        const crashPointLayer = getLayer(DiagramLayer.crashPoint);
        crashPointLayer.data.lat = loc.Latitude.toFixed(12).toString();
        crashPointLayer.data.lng = loc.Longitude.toFixed(12).toString();
    }

    async getGeolocationCandidate(
        locationType: string,
        latLng: LatLng
    ): Promise<
        { Location: GeoServiceLocation; ServiceResult: any } | undefined
    > {
        let addressData: GeocodingAddressInput = {
            stateShort: "FL"
        };

        if (locationType == "OFFROADWAY") {
            const reverseGeocodeResult = await this.reverseGeocode(latLng);
            if (reverseGeocodeResult) addressData = reverseGeocodeResult;
        }

        let queryString = "?";

        for (const entry of Object.entries(addressData)) {
            if (entry[1]) {
                queryString += entry[0] + "=" + entry[1] + "&";
            }
        }

        queryString.slice(0, -1); // remove trailing "&"

        const url =
            `${this.geoServiceBase}/geocode/json/` +
            `${this.geoServiceKey}/${latLng.lat}/${latLng.lng}/FL9999999/${locationType}` +
            queryString;

        return fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to get location data");
                }

                return response.json();
            })
            .then((json) => {
                if (json.Location) {
                    return json;
                } else {
                    throw new Error(json.ServiceResult.GeoServiceErrorMessage);
                }
            })
            .catch(async (error) => {
                await promptBox("Geocoding Error", error);
                return undefined;
            });
    }

    async reverseGeocode(
        latLng: LatLng
    ): Promise<GeocodingAddressInput | undefined> {
        const url = // remember, location is in the format (lng, lat)
            "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode" +
            `?f=json&featureTypes=PointAddress&location=${latLng.lng},${latLng.lat}`;

        return fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to reverse geocode");
                }
                return response.json();
            })
            .then((json) => {
                if (json.error) {
                    throw new Error(json.error.details.join("\n"));
                }
                return this.parseEsriReverseGeocodeResponse(json);
            })
            .catch(async (error) => {
                // await promptBox("Reverse Geocoding Error", error);
                // console.log(error);
                return undefined;
            });
    }

    parseEsriReverseGeocodeResponse(esriResponse: any): GeocodingAddressInput {
        const address = esriResponse.address;
        return {
            streetNbr: address.AddNum,
            streetNm:
                address.Address.indexOf(address.AddNum) != -1
                    ? address.Address.slice(address.AddNum.length + 1)
                    : address.Address,
            cityOrPlace: address.City,
            county: address.Subregion,
            stateShort: address.RegionAbbr,
            postalCode: address.Postal
        };
    }

    /** Populates var geoServiceData with an S4 Geolocation GeoServiceData object */
    private async loadGeoServiceData() {
        if (!this.ids.artifactId) {
            await this.ids.alertMissingIds("GeoServiceRecordId");
            return;
        }

        if (!this.ids.vendorId) {
            await this.ids.alertMissingIds("Vendor ID");
            return;
        }

        const url = `${this.geoServiceBase}/getbyid/json/${this.geoServiceKey}/${this.ids.artifactId}`;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(
                    "Failed to get the corresponding geoServiceRecord."
                );
            }
            const json = await response.json();
            this.geoServiceData = json;
            this.originalLocation = json.Location;
            this.geoDataIsLoaded.value = true;
        } catch (error) {
            await promptBox(
                "Location Data Error",
                contactSupportMessage("S4 Geolocation")
            );
            window.close();
        }
    }

    async getLocationDirty(): Promise<string | undefined> {
        const url = `api/diagram/get-location-dirty/${this.ids.artifactId}/${this.ids.vendorId}`;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(await response.text());
            }
            const text = await response.text();
            return text;
        } catch (error) {
            console.log(error);
            return undefined;
        }
    }

    static updateLocationRecord(location: GeoServiceLocation) {
        return this.injectedInstance.updateLocationRecord(location);
    }

    updateLocationRecord(location: GeoServiceLocation): Promise<boolean> {
        const oldLoc = this.geoServiceData.Location;
        this.geoServiceData.Location = location; // update the current location in memory

        const url = "api/diagram/reposition-crash-point";
        const payload = new FormData();
        payload.append(
            "geoServiceDataString",
            JSON.stringify(this.geoServiceData)
        );

        return fetch(url, {
            method: "PUT",
            body: payload
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to update location record.");
                }

                return response.json();
            })
            .then((json) => {
                if (json.GeoServiceResult == 0) {
                    this.saveLatLngToCrashPointLayer(location);
                    this.setLocationDirty();
                    return true;
                } else {
                    throw new Error(json.GeoServiceErrorMessage);
                }
            })
            .catch(async (error) => {
                this.geoServiceData.location = oldLoc;

                await promptBox(
                    "Location Update Error",
                    contactSupportMessage("S4 Geolocation")
                );
                return false;
            });
    }

    /** Updates the dirty flag based on whether or not the crash point location has been changed
     *  this session or to the supplied parameter value */
    private setLocationDirty(forceValue?: "Y" | "N") {
        if (this.originalLocationWasDirty) return; // if the location started dirty, this can simply be skipped

        const isDirty =
            forceValue ||
            JSON.stringify(this.geoServiceData.Location) ==
                JSON.stringify(this.originalLocation)
                ? "N"
                : "Y";

        const url = `api/diagram/set-location-dirty/${this.ids.artifactId}/${this.ids.vendorId}?val=${isDirty}`;

        fetch(url, {
            method: "PUT"
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        `Failed to set location dirty to ${isDirty}`
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // async openGeolocMenu() {
    //     setCursor(Cursor.Arrow);

    //     const originalRelToNetwork =
    //         this.originalLocation.RelationshipToNetwork;
    //     const viewPoint = paper.view.projectToView(
    //         DiagramService.crashPointObject.position
    //     );
    //     const candidate = await this.getGeolocationCandidate(
    //         originalRelToNetwork,
    //         paperPointToLatLng(viewPoint)
    //     );

    //     if (candidate) {
    //         if (candidate.ServiceResult.GeoServiceResult == 0) {
    //             this.ui.geolocMenu.locationFound = true;
    //         } else {
    //             this.ui.geolocMenu.locationFound = false;
    //         }

    //         this.ui.geolocMenu.newLocation = candidate.Location;
    //         this.ui.showGeolocWindow = true;
    //         this.ui.geolocMenu.updateMenu();
    //     } else {
    //         await promptBox(
    //             "Geolocation Failure",
    //             "Sorry, you will need return to S4 Geolocation (location tool) to edit the point of impact. " +
    //                 "Close this page and come back when finished accepting the new point."
    //         );
    //     }
    // }
}
