<div id="wrapper-wrapper">
    <div id="wrapper">
        <img
            id="screen-shot"
            src="assets/tracs_crashform_diagram_screenshot.png"
        />
        <button
            id="open"
            routerLink="/diagram"
            routerLinkActive="active"
            [queryParams]="{ id: artId, vi: vendorId }"
        >
            Open
        </button>
        <div id="img-wrapper">
            <img id="diagram-img" [src]="diagramImage" *ngIf="diagramImage" />
        </div>
    </div>
</div>
