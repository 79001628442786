import { ContextMenuComponent } from "@progress/kendo-angular-menu/context-menu/context-menu.component";
import { getDiagramZoomScale } from "./diagram-map";
import { getInverseScaling, loadSVGFromFile } from "./helpers";
import { Cursor, clearCursor, setCursor } from "./cursor";
import * as paper from "paper";

// The following var and function allow manipulation of the Kendo component from this script (and imported into ...events.ts)
export var ellipsisMenu: ContextMenuComponent;
// =========================================================================================
var ellipsisDef: paper.SymbolDefinition;
var ellipsisBg: paper.Path.Circle;
export var ellipsisDrawing: paper.Group;

function restoreEllipsis(): boolean {
    const symbol = paper.project.getItem({
        class: "SymbolItem",
        name: "ellipsis button"
    }) as paper.SymbolItem;

    if (!symbol) return false;

    ellipsisDef = symbol.definition;
    ellipsisDrawing = symbol.definition.item as paper.Group;
    ellipsisBg = ellipsisDrawing.children[0] as paper.Path.Circle;

    return true;
}

export function scaleEllipsis() {
    if (ellipsisDrawing) ellipsisDrawing.scaling = getInverseScaling();
}

export async function setUpEllipsisButton(menu: ContextMenuComponent) {
    ellipsisMenu = menu;
    if (restoreEllipsis()) return;

    const ellipsis = await loadSVGFromFile(
        "assets/SVG/Misc Icons/ellipsis-v.svg"
    );

    ellipsis.scale(0.065);

    ellipsisBg = new paper.Path.Circle({
        center: ellipsis.position,
        radius: ellipsis.bounds.width * 3.5,
        fillColor: "#f3f3f3",
        opacity: 0.5
    });

    ellipsisDrawing = new paper.Group([ellipsisBg, ellipsis]);
    ellipsisDrawing.data.thing = "ellipsis button";
    ellipsisDrawing.name = "ellipsis button";
    // drawing.visible = true;
    ellipsisDrawing.applyMatrix = false;
    scaleEllipsis();

    ellipsisDef = new paper.SymbolDefinition(ellipsisDrawing);
}

export function getEllipsisSymbol(p?: paper.Point) {
    const symbol = ellipsisDef.place(p);
    symbol.visible = false;
    symbol.name = "ellipsis button";
    return symbol;
}

export function setUpEllipsisButtonHandlers(ellipsisSymbol: paper.SymbolItem) {
    ellipsisSymbol.onMouseEnter = (e: paper.MouseEvent) => {
        ellipsisBg.opacity = 1;
        setCursor(Cursor.Pointer);
    };

    ellipsisSymbol.onMouseLeave = (e: paper.MouseEvent) => {
        ellipsisBg.opacity = 0.5;
        clearCursor();
    };

    ellipsisSymbol.onMouseUp = (e: paper.MouseEvent) => {
        if (
            paper.view.projectToView(
                ellipsisSymbol.parent.localToGlobal(ellipsisSymbol.position)
            ).y <
            ellipsisMenu.items.length * 21
        ) {
            ellipsisMenu.popupAlign.vertical = "top";
            ellipsisMenu.popupAnimate = {
                direction: "down",
                duration: 100
            };
        } else {
            ellipsisMenu.popupAlign.vertical = "bottom";
            ellipsisMenu.popupAnimate = {
                direction: "up",
                duration: 100
            };
        }

        // const rect = paper.view.element.getBoundingClientRect();
        // const canvasCorner = new paper.Point(rect.left, rect.top);
        // const offset = paper.view.projectToView(e.point.add(new paper.Point(canvasCorner.x, canvasCorner.y)));
        // // const offset = paper.view.projectToView(
        // //     e.point.add(paper.view.bounds.topLeft.divide(getDiagramZoomScale()))
        // // );

        const rect = paper.view.element.getBoundingClientRect();
        const canvasCorner = new paper.Point(rect.left, rect.top);
        const offset = paper.view.projectToView(
            e.point.add(canvasCorner.divide(getDiagramZoomScale()))
        );

        ellipsisMenu.show({ left: offset.x, top: offset.y });
    };
}
