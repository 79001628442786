import * as paper from "paper";
import { v4 as uuidv4 } from "uuid";
import { colorCodeRGB } from "./icon";
import { getLayer } from "./layer";
import {
    BASE_ZOOM,
    BoundingBox,
    getLatLngAsPaperPoint,
    leafletMap,
    paperPointToLatLng
} from "./diagram-map";
import { DiagramService } from "./services/diagram.service";
import { DiagramObject } from "../classes/DiagramObject";
import { GroupSelection } from "../classes/GroupSelection";

export function wrapIndex(index: number, length: number) {
    return ((index % length) + length) % length;
}

export function roundToPrecision(number: number, precision: number) {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
}

export async function pause(length: number) {
    return new Promise<void>((res) => {
        setTimeout(() => {
            res();
        }, length);
    });
}

/** Returns a paper Item generated from the specified SVG URL */
export async function loadSVGFromFile(
    iconUrl: string,
    show = true
): Promise<paper.Item> {
    return new Promise((resolve, reject) => {
        paper.project.importSVG(iconUrl, {
            insert: show,
            onLoad: (item: paper.Item) => resolve(item),
            onError: (error: Error) => {
                console.error(error.message);
                reject(error);
            }
        });
    });
}

/** Returns a paper Item generated from a SVG on the page matching the provided iconId and orientation, colored with the provided colorCode */
export function loadSVGFromPage(
    iconId: string,
    colorCode = "SIL",
    orientation = "Top",
    isHitAndRun = false
): paper.Item {
    if (orientation == "Standing") orientation = "Top";

    const iconSet = document.querySelector(`#${orientation}-View`);

    let icon = iconSet?.querySelector(
        `[data-icon-id="${iconId + (isHitAndRun ? "-hit-and-run" : "")}"]`
    ) as SVGElement;

    if (!icon) {
        icon = iconSet?.querySelector(
            `[data-icon-id="${iconId}"]`
        ) as SVGElement;

        if (!icon && orientation != "Top") {
            return loadSVGFromPage(iconId, colorCode, "Top");
        }
    }

    const colorables = icon.querySelectorAll(".colorable");

    if (colorables) {
        const fillColor = colorCodeRGB[colorCode];
        for (const colorable of Array.from(colorables) as Array<HTMLElement>) {
            for (const path of Array.from(
                colorable.children
            ) as Array<HTMLElement>) {
                path.setAttribute("fill", fillColor);
            }
        }
    }

    return paper.project.importSVG(icon);
}

/** A unique ID independent from paper.js survives imports/exports */
export function getUniqueId(): string {
    return uuidv4();
}

export function oppositeOrientation(orientation: string | undefined): string {
    if (orientation) {
        switch (orientation.toUpperCase()) {
            case "N":
            case "NORTH":
                return "S";
            case "E":
            case "EAST":
                return "W";
            case "S":
            case "SOUTH":
                return "N";
            case "W":
            case "WEST":
                return "E";
            default:
                return orientation;
        }
    }

    return "Unknown";
}

/** Positions the ellipsis button at the top-right corner of the selection controls. To be used after transformations that inadvertently
 * move the button out of this position. */
export function positionEllipsisButton(
    ellipsisBtn: paper.Item,
    innerGroup: paper.Item
) {
    const offset = innerGroup.bounds.width * 0.12;
    ellipsisBtn.rotation = 0;

    ellipsisBtn.bounds.bottomLeft = innerGroup.bounds.topRight.subtract(
        new paper.Point(offset, -offset)
    );
}

/** A verbosity reducer that makes sure the "object core" is centered within its selection controls */
export function centerIconInControls(item: paper.Item) {
    item.position =
        item.parent.children["selection controls"].children[
            "selection circle"
        ].position;
}

/** Returns the "diagram object" containing "part" */
export function getDiagramObjectContaining(part: paper.Item) {
    if (!part) return part;

    if (part.name == "object core") {
        return part.parent.parent;
    } else if (part.name == "inner group") {
        return part.parent;
    }

    while (
        part.data.thing != "diagram object" &&
        part.data.thing != "crash point object" &&
        part.data.thing != "group selection outer group"
    ) {
        part = part.parent;
    }

    return part;
}

export function getEllipsisPositionForLine(line: paper.Path): paper.Point {
    const zoomScale = getDiagramZoomScale();

    return new paper.Point(
        line.firstSegment.point.add(
            line
                .getTangentAt(0)
                .rotate(180, new paper.Point(0, 0))
                .multiply(35 / zoomScale)
        )
    );
}

export function getDiagramObjectsBounds() {
    let objects: paper.Item[] = [];

    if (DiagramObject.selectedObject instanceof GroupSelection) {
        DiagramObject.deselect();
    }

    for (const obj of DiagramService.diagramObjectIterator()) {
        if (obj.entireObject.isInserted())
            objects.push(obj.entireObject);
    }

    const group = new paper.Group(objects);
    const bounds = group.bounds;
    ungroup(group);

    return bounds;
}

export function getDiagramBoundsRect(): paper.Rectangle {
    let boundingRect = getDiagramObjectsBounds();

    const cropArea = paper.project.getItem({ data: { thing: "crop area" } });
    if (cropArea && cropArea.isInserted()) {
        boundingRect = boundingRect.unite(cropArea.bounds);
    }

    return boundingRect;
}

export function getDiagramBounds(boundsPadding?: number) {
    let boundingRect = getDiagramBoundsRect();

    if (boundsPadding) {
        boundingRect = boundingRect.expand(boundsPadding);
    }

    boundingRect = new paper.Rectangle(
        paper.view.projectToView(boundingRect.topLeft),
        paper.view.projectToView(boundingRect.bottomRight)
    );

    let bounds: BoundingBox = {
        left: boundingRect.left,
        top: boundingRect.top,
        right: boundingRect.right,
        bottom: boundingRect.bottom
    };

    return bounds;
}

const propRegEx = /([a-zA-Z_$][a-zA-Z0-9_$]*)|\[([0-9]+)\]/g;

export function getNestedProperty(
    obj: object,
    path: string
): [any, object, string] {
    let value: any = obj;
    const keys: string[] = [];

    for (const match of path.matchAll(propRegEx)) {
        keys.push(match.slice(1).find((v: string) => v)!);
    }

    try {
        for (const key of keys) {
            if (value && typeof value === "object") obj = value;
            value = value[key];
        }
    } catch (error) {
        console.error(error);
        value = undefined;
    }

    const subProp = keys.at(-1)!;
    return [value, obj, subProp];
}

Object.defineProperty(window, "getNestedValue", { value: getNestedProperty });

/** It is important for this project not to save extraneous JSON (such as an empty group).
 * This method removes the supplied group's children, and places them into their corresponding layers.
 * It then removes the empty group from the diagram.
 */
export function ungroup(g: paper.Group) {
    if (!g) return;

    for (const child of g.removeChildren()) {
        if (child.data.thing == "arrow") child.locked = false;
        getLayer(child.data.originalLayer).addChild(child);
    }

    g.remove();
}

// export function updateCrashIconLocation(crashLoc: GeoServiceLocation) {
//    setLatLng(
//         new L.LatLng(crashLoc.Latitude, crashLoc.Longitude)
//     );
//     repositionDiagram();
//     applyNewLatLng();
// }

export function getDiagramZoomScale() {
    return leafletMap.getZoomScale(leafletMap.getZoom(), BASE_ZOOM);
}

export function getDashablePart(item: paper.Item): paper.Item | null {
    if (item.dashArray) {
        return item;
    }

    return item.getItem({ data: { dashable: true } });
}

export function repositionNodes(
    nodeList: paper.Group,
    path: paper.Path,
    includeEdges = false
) {
    if (includeEdges) {
        for (let i = 0, j = 0; i < path.segments.length; ++i, j += 2) {
            nodeList.children[j].position = path.segments[i].point;
            nodeList.children[j + 1].position =
                path.segments[i].curve.getPointAtTime(0.5);
        }
    } else {
        for (let i = 0; i < path.segments.length; ++i) {
            nodeList.children[i].position = path.segments[i].point;
        }
    }
}

export function repositionRotationNode(
    rotationNode: paper.Group,
    path: paper.Path
) {
    const offset = path.getNearestLocation(path.bounds.topCenter).offset;
    const p1 = path.getPointAt(offset);
    const normalJut = path
        .getNormalAt(offset)
        .multiply(30)
        .divide(path.parent.scaling);
    let p2: paper.Point;

    if (normalJut.y < 0) {
        p2 = p1.add(normalJut);
    } else {
        p2 = p1.subtract(normalJut);
    }

    const line = rotationNode.children[0] as paper.Path;
    line.segments[0].point = p1;
    line.segments[1].point = p2;

    rotationNode.children[1].position = p2;
}

export function getResizeCursorFor(node: paper.Item, shape: paper.Path) {
    let angle =
        node.position.subtract(shape.position).angle + shape.parent.rotation;

    // limit angle to the -180 to 180 range
    angle = to180Angle(angle);
    const half45 = 45 / 2;
    let cursor = "ew-resize";

    if (
        (angle >= 90 + half45 && angle <= 180 - half45) ||
        (angle <= -half45 && angle >= -90 + half45)
    ) {
        cursor = "nesw-resize";
    } else if (
        (angle > 45 + half45 && angle < 135 - half45) ||
        (angle < -45 - half45 && angle > -135 + half45)
    ) {
        cursor = "ns-resize";
    } else if (
        (angle <= -90 && angle > -180 + half45) ||
        (angle >= 0 + half45 && angle <= 90 - half45)
    ) {
        cursor = "nwse-resize";
    }

    return cursor;
}

export function repositionTextboxes(p: paper.Point) {
    p = paper.view.projectToView(p);
    const textboxDiv = document.querySelector("#textboxes") as HTMLDivElement;
    textboxDiv.style.left = `${p.x}px`;
    textboxDiv.style.top = `${p.y}px`;
}

/** Returns +1, -1 or 0 depending on which side a line from point a to point b the test point falls */
export function getPositionRelativeToLine(
    a: paper.Point,
    b: paper.Point,
    test: paper.Point
) {
    return Math.sign(b.subtract(a).cross(test.subtract(a)));
}

/** Returns the corresponding angle within the -180 to 180 degree range */
export function to180Angle(angle: number) {
    return ((angle % 180) + 180) % 180;
}

export async function flash(item: paper.Item, times: number = 3) {
    const fadeAndBack = function () {
        return new Promise<void>((res) => {
            item.tweenTo({ opacity: 0 }, 250).then(() => {
                item.tweenTo({ opacity: 1 }, 250).then(() => res());
            });
        });
    };

    for (let i = 0; i < times; ++i) {
        await fadeAndBack();
    }
}

export function degreesToRadians(degrees: number) {
    return (degrees * Math.PI) / 180;
}

export function repositionDiagram(): void {
    const latLngPoint = getLatLngAsPaperPoint();

    for (const layer of paper.project.layers) {
        layer.position = latLngPoint;
    }

    repositionTextboxes(latLngPoint);
}

export function getInverseScaling(zoomScale?: number) {
    if (!zoomScale) zoomScale = getDiagramZoomScale();
    return new paper.Point(1 / zoomScale, 1 / zoomScale);
}

/** Converts p1 and p2 to view points then leaflet LatLng and returns their distance */
export function getMapDistanceInFeet(p1: paper.Point, p2: paper.Point) {
    const p1View = paper.view.projectToView(p1);
    const p2View = paper.view.projectToView(p2);
    const latLng1 = paperPointToLatLng(p1View);
    const latLng2 = paperPointToLatLng(p2View);
    return metersToFeet(leafletMap.distance(latLng1, latLng2));
}

export function metersToFeet(meters: number) {
    return meters * 3.280839895;
}

export function getLineAngle(line: paper.Path) {
    const p1 = line.localToGlobal(line.lastSegment.point);
    const p2 = line.localToGlobal(line.firstSegment.point);
    return p1.subtract(p2).angleInRadians;
}

export function addSpacesBetweenWords(words: string): string {
    if (words) {
        return words.replace(/([A-Z])(.{1})/g, " $1$2").trim();
    } else {
        return "?";
    }
}

type JsonPathSegment = string | number;

export class JSONPointer {
    static generate(jsonDocument: any, targetData: any): string {
        const path: JsonPathSegment[] = this.findPathToTarget(
            jsonDocument,
            targetData,
            []
        );
        return path.length > 0
            ? "/" + path.map(this.escapeSegment).join("/")
            : "";
    }

    private static escapeSegment(segment: JsonPathSegment): string {
        if (typeof segment === "string") {
            return segment.replace(/~/g, "~0").replace(/\//g, "~1");
        }
        return segment.toString();
    }

    private static findPathToTarget(
        element: any,
        target: any,
        currentPath: JsonPathSegment[]
    ): JsonPathSegment[] {
        if (element === target) {
            return currentPath;
        }

        if (element !== null && typeof element === "object") {
            for (const key in element) {
                if (element.hasOwnProperty(key)) {
                    const path = this.findPathToTarget(
                        element[key],
                        target,
                        currentPath.concat(key)
                    );
                    if (path.length) {
                        return path;
                    }
                }
            }
        }

        return [];
    }

    static evaluate(jsonDocument: any, pointer: string): any {
        if (!pointer) {
            return jsonDocument;
        }

        // Split the pointer into segments, ignoring the first empty segment (since pointers start with '/')
        const segments = pointer
            .substring(1)
            .split("/")
            .map(this.unescapeSegment);

        let currentElement = jsonDocument;
        for (const segment of segments) {
            if (segment in currentElement) {
                currentElement = currentElement[segment];
            } else {
                // If any segment is not found, return undefined
                return undefined;
            }
        }

        return currentElement;
    }

    private static unescapeSegment(segment: string): string | number {
        const unescaped = segment.replace(/~1/g, "/").replace(/~0/g, "~");
        // If the segment is a number, return it as a number. Otherwise, return the unescaped string.
        return isNaN(Number(unescaped)) ? unescaped : Number(unescaped);
    }
}

export function drawDebugCircle(point: paper.Point, color = "red") {
    const c = new paper.Path.Circle(point, 15);
    c.strokeColor = new paper.Color(color);
}
