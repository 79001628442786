// Constants
import * as L from "leaflet";
import * as paper from "paper";
import Watchable from "@blakecanter/watchablejs";
import { DiagramData } from "../models/diagram-models";

export const DIAGRAM_STYLES = ["Color", "B&W Map", "All B&W"];

export var leafletMap: L.Map;
export var crashLatLng: L.LatLng;
export var baseMaps: L.Control.LayersObject;
export var baseMapToStringMap: Map<L.Layer, string>;
export var stringToBaseMapMap: Map<string, L.Layer>;
export var selectedLayer: string;
export var diagramStyle = DIAGRAM_STYLES[0];
export var currentOpacity = 0.3;
export var setupIsComplete = new Watchable(false);
export const BASE_ZOOM = 21;

export interface BoundingBox {
    left: number;
    top: number;
    right: number;
    bottom: number;
}

export function getDiagramZoomScale() {
    return leafletMap.getZoomScale(leafletMap.getZoom(), BASE_ZOOM);
}

/**
 * Check if map is at max zoom.
 * @returns boolean - true if at max zoom, false otherwise.
 */
export function isAtMaxZoom(): boolean {
    return (
        leafletMap !== undefined &&
        leafletMap.getZoom() >= leafletMap.getMaxZoom()
    );
}

/**
 * Check if map is at min zoom.
 * @returns boolean - true if at min zoom, false otherwise.
 */
export function isAtMinZoom(): boolean {
    return (
        leafletMap !== undefined &&
        leafletMap.getZoom() <= leafletMap.getMinZoom()
    );
}

/**
 * Setup the map.
 * @param el The HTML element that will contain the map.
 * @param lat The latitude to center the map in.
 * @param lng The longitude to center the map in.
 */
export function setUpMap(
    el: HTMLElement,
    lat = 29.65207947396467629,
    lng = -82.33973376452923
): void {
    crashLatLng = new L.LatLng(lat, lng);

    // define map layers
    const googleLayer = L.tileLayer(
        "https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
        {
            maxZoom: 22,
            minZoom: 16,
            subdomains: ["mt0", "mt1", "mt2", "mt3"]
        }
    );

    const mapboxLayer = L.tileLayer(
        "https://api.mapbox.com/styles/v1/signalfourlab/cke093xb808qi19nmufwv3r6l/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic2lnbmFsZm91cmxhYiIsImEiOiJjazFzYTd6aXcwYTJtM2xvMGI2Y3dyN3JwIn0.OnpaWvdIZ2bmaEdvCspfPQ",
        {
            maxZoom: 22,
            minZoom: 16
        }
    );

    // const cartographic = L.tileLayer(
    //     "https://api.mapbox.com/styles/v1/signalfourlab/ckfctxkwu004e19p9h7loagy9/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic2lnbmFsZm91cmxhYiIsImEiOiJjazFzYTd6aXcwYTJtM2xvMGI2Y3dyN3JwIn0.OnpaWvdIZ2bmaEdvCspfPQ",
    //     {
    //         maxZoom: 22,
    //         minZoom: 20
    //     }
    // );

    // const grayscale = L.tileLayer(
    //     "https://api.mapbox.com/styles/v1/signalfourlab/ckliezwsl12wh17n22vy39ch4/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic2lnbmFsZm91cmxhYiIsImEiOiJjazFzYTd6aXcwYTJtM2xvMGI2Y3dyN3JwIn0.OnpaWvdIZ2bmaEdvCspfPQ",
    //     {
    //         maxZoom: 22,
    //         minZoom: 20
    //     }
    // );

    // const mbUrl =
    //     "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";

    // const esriLayer = L.tileLayer(mbUrl, { maxZoom: 20, minZoom: 20 });

    baseMaps = {
        // Cartographic: cartographic,
        // Grayscale: grayscale,
        Google: googleLayer,
        // Esri: esriLayer,
        Mapbox: mapboxLayer
    };

    baseMapToStringMap = new Map<L.Layer, string>([
        // [cartographic, "cartographic"],
        // [grayscale, "grayscale"],
        [googleLayer, "google"],
        // [esriLayer, "esri"],
        [mapboxLayer, "mapbox"]
    ]);

    stringToBaseMapMap = new Map<string, L.Layer>([
        ["google", googleLayer],
        ["mapbox", mapboxLayer]
        // ["esri", esriLayer],
        // ["cartographic", cartographic],
        // ["grayscale", grayscale],
    ]);

    selectedLayer = "google";

    // create the leaflet map object

    leafletMap = leafletMap = L.map(el, {
        center: crashLatLng,
        zoom: BASE_ZOOM,
        layers: [googleLayer],
        zoomControl: false,
        attributionControl: false
    });

    leafletMap.boxZoom.disable();
    leafletMap.doubleClickZoom.disable();
    setMapOpacity(currentOpacity); // set the default opacity
    setupIsComplete.value = true;
}

export function setLayer(
    layer: string,
    opacity: number = currentOpacity
): void {
    leafletMap.eachLayer((l) => leafletMap.removeLayer(l));
    leafletMap.addLayer(stringToBaseMapMap.get(layer) as L.Layer);
    selectedLayer = layer;
    setMapOpacity(opacity);
}

export function setStyle(style: number | string) {
    if (typeof style === "number") {
        diagramStyle = DIAGRAM_STYLES[style];
    } else {
        diagramStyle = style;
    }
}

/**
 * Set the latitude on the map.
 * @param newLatLng The new latitude.
 */
export function setLatLng(newLatLng: L.LatLng) {
    crashLatLng = newLatLng;
}

/**
 * Set the opacity of the map.
 *
 * Note: it changes the opacity for all the layers of the map.
 * @param value The new opacity of the layers.
 */
export function setMapOpacity(value: number): void {
    currentOpacity = value;

    leafletMap.eachLayer((l) => {
        const tl = l as L.TileLayer;
        tl.setOpacity(value);
    });
}

/**
 * Determine the latitude and longitude of the bounding box provided.
 * @param bounds The bounding box. It is a BoundingBox interface that need to be passed.
 * @interface BoundingBox
 * @returns L.latLnBounds The point of the bound
 */
export function getLatLngBoundsFromBoundingBox(bounds: BoundingBox) {
    let p1 = leafletMap.containerPointToLatLng(
        new L.Point(bounds.left, bounds.top)
    );
    let p2 = leafletMap.containerPointToLatLng(
        new L.Point(bounds.right, bounds.bottom)
    );
    return L.latLngBounds(p1, p2);
}

/**
 * Fit the Map's bounds with the padding
 * @param bounds The bounding box that we need to fit.
 * @interface BoundingBox
 * @param options The options of the fit.
 */
export function fitMapBoundsWithPadding(
    bounds: BoundingBox,
    options: L.FitBoundsOptions
) {
    const latLngBounds = getLatLngBoundsFromBoundingBox(bounds);
    leafletMap.fitBounds(latLngBounds, options);
}

/**
 * Reset the map to a bound and option passed or to the default latitude and longitude.
 * @param options The options to fit the bounds
 * @param bounds The bound that need to fit
 * @interface BoundingBox
 */
export function goHome(options?: L.FitBoundsOptions, bounds?: BoundingBox) {
    if (bounds && options) {
        fitMapBoundsWithPadding(bounds, options);
    } else {
        leafletMap.setView(crashLatLng, BASE_ZOOM);
    }
}

/**
 *
 * @param diagramData
 */
export function configureMap(diagramData: DiagramData) {
    leafletMap.dragging.disable();
    leafletMap.scrollWheelZoom.disable();
    leafletMap.setZoom(diagramData.zoom, { animate: false }).panTo(
        {
            lat: diagramData.lat,
            lng: diagramData.lng
        },
        { animate: false }
    );
    setLayer(diagramData.tiles, diagramData.opacity);
    if (diagramData.styleCD) {
        diagramStyle = DIAGRAM_STYLES[diagramData.styleCD];
    }
}

export function paperPointToLeafletPoint(p: paper.Point): L.Point {
    let point = new L.Point(p.x, p.y);
    return point;
}

export function leafletPointToPaperPoint(p: L.Point): paper.Point {
    let point = new paper.Point(p.x, p.y);
    return point;
}

export function paperPointToLatLng(p: paper.Point): L.LatLng {
    return leafletMap.containerPointToLatLng(paperPointToLeafletPoint(p));
}

export function latLngToPaperPoint(latLng: L.LatLng) {
    return leafletPointToPaperPoint(leafletMap.latLngToContainerPoint(latLng));
}

export function getLatLngAsPaperPoint(): paper.Point {
    return paper.view.viewToProject(
        leafletPointToPaperPoint(leafletMap.latLngToContainerPoint(crashLatLng))
    );
}

export function disableMapDragging() {
    leafletMap.dragging.disable();
}

export function enableMapDragging() {
    leafletMap.dragging.enable();
}
