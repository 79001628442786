import { GeometricShape, GeometricShapeNode } from "./GeometricShape";
import * as paper from "paper";
import { GeometricShapeBuilder } from "./AnnotationBuilders";
import { DiagramLayer } from "src/app/shared/layer";
import { Annotation } from "./Annotation";

export class PolygonToolBuilder extends GeometricShapeBuilder {
    protected initialLayer: DiagramLayer = DiagramLayer.backgroundAnnotations;

    protected startShape(point: paper.Point): void {
        const polygon = new paper.Path.Line({
            from: point,
            to: point,
            name: "shape",
            strokeColor: Annotation.defaultStrokeColor
                ? Annotation.defaultStrokeColor
                : new paper.Color("black"),
            strokeWidth: Annotation.defaultStrokeWidth,
            fillColor: Annotation.defaultFillColor,
            dashArray: Annotation.defaultDashArray,
            strokeCap: "round",
            data: {
                thing: "annotation polygon",
                category: "annotation"
            }
        });

        this.unfinishedAnno = this.initAnno(polygon);
        this.unfinishedAnno.addChild(this.createStopNode(point));
    }

    protected continueShape(point: paper.Point): void {
        if (!this.unfinishedAnno) return;

        this.shape.lastSegment.point = point;

        if (this.stopNode.contains(point)) {
            paper.view.off(this.activeHandlers);
            this.shape.lastSegment.remove();
            this.shape.closePath();
            this.stopNode.remove();

            this.addRotationNode();
            this.unfinishedAnno.applyMatrix = false;
            this.completeShape();
        } else {
            this.shape.add(new paper.Segment(point));
        }
    }

    protected toolMove = (e: paper.MouseEvent) => {
        if (!this.unfinishedAnno) return;

        const polygon = this.unfinishedAnno.children["shape"] as paper.Path;
        polygon.lastSegment.point = e.point;

        if (!this.stopNode.strokeColor && this.stopNode.contains(e.point)) {
            this.stopNode.strokeColor = new paper.Color("white");
        } else if (
            this.stopNode.strokeColor &&
            !this.stopNode.contains(e.point)
        ) {
            this.stopNode.strokeColor = null;
        }
    };

    protected createResizeNodes(): paper.Path.Circle[] {
        return this.makeNodesAlongShape(PolygonNode, false);
    }

    build(): GeometricShape {
        const polygon = new GeometricShape(this.unfinishedAnno!);
        polygon.repositionRotationNode();
        return polygon;
    }
}

export class PolygonNode extends GeometricShapeNode {
    protected applyClassName() {
        this.className = "PolygonNode";
    }

    get parentElement(): GeometricShape {
        return super.parentElement as GeometricShape;
    }

    onMouseDrag = (e: paper.MouseEvent) => {
        const node = e.currentTarget;
        const shape = this.parentElement.shape;

        const localEPoint = shape.globalToLocal(e.point);

        node.position = localEPoint;
        shape.segments[node.index].point = localEPoint;

        this.parentElement.positionEllipsis();
        this.parentElement.repositionRotationNode();
    };
}
