// A super simple 2D point class when just an x and y value and adding/subtracting is needed
export default class Point {
    x = 0;
    y = 0;

    constructor();
    constructor(x: number, y: number);
    constructor(p: Point);
    constructor(xp: number | Point = 0, y = 0) {
        if (xp instanceof Point) {
            this.x = xp.x;
            this.y = xp.y;
        } else {
            this.x = xp;
            this.y = y;
        }
    }

    /** Adds supplied point and returns this for chaining */
    add(p: Point) {
        this.x += p.x;
        this.y += p.y;
        return this;
    }

    /** Subtracts supplied point and returns this for chaining */
    subtract(p: Point) {
        this.x -= p.x;
        this.y -= p.y;
        return this;
    }

    clone() {
        return new Point(this);
    }
}
